import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Col, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/Button';
import Fieldset from '../../../components/Fieldset';
import If from '../../../components/If';
import { STRINGS } from '../../../util/strings';
import {
	ApplicantResults, BreederResults, CultureResults, EventsResult, ModelFileRegister, ModelFilesProtection,
	RequestProtectionApplicants, RequestProtectionMelhoristas, RequestProtectionSNPC, Status, TypeAttachment,
	ArquivosDescritores, ArquivosRelatorioTecnico

} from '../../../models/models';
import { protectionSNPC } from '../../../tempdb.json';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect } from 'react';
import api, { apiFileDownload } from '../../../services/api';
import FileExistent from '../../../components/FileExistent';
import moment from 'moment';
import Loading from '../../../components/Loading';
import NotAvailableModel from '../../../components/NotAvailableModel';
import LoadingInForm from '../../../components/LoadingInForm';
import { STATUS_PROTECTION, STATUS_PROTECTION_ARCHIVE, STATUS_PROTECTION_ATTACHMENT } from '../../../util/status';
import Modal from '../../../components/Modal';
import EditButton from '../../../components/EditButton';
import '../styles_view.css';

registerLocale('pt-BR', ptBR);

interface stateType {
	id: number;
}

export default function ProtectionSNPC ()
{
	const [codigoRequisicao, setCodigoRequisicao] = useState<string>('');
	const history = useHistory();
	const location = useLocation<stateType>();
	const [insertApplicants, setInsertApplicants] = useState<boolean>(false);
	const [selectedApplicants, setSelectedApplicants] = useState<Array<ApplicantResults>>([]);
	const [insertBreeder, setInsertBreeder] = useState<boolean>(false);
	const [selectedBreeder, setSelectedBreeder] = useState<Array<BreederResults>>([]);
	const [transferredCultivar, setTransferredCultivar] = useState<boolean>(false);
	const [neededSpecifyModality, setNeededSpecifyModality] = useState<boolean>(false);
	const [lblModality, setLblModality] = useState('Escolher arquivo');
	const [modifiedOrganism, setModifiedOrganism] = useState<boolean>(false);
	const [cultivarProtection, setCultivarProtection] = useState<boolean>(false);
	const [registerCultivarProtection, setRegisterCultivarProtection] = useState<boolean>(false);
	const [protectionOfCultivarItems, setProtectionOfCultivarItems] = useState<Array<object>>([]);
	const [cultivarInBrazil, setCultivarInBrasil] = useState<boolean>(false);
	const [cultivarOutside, setCultivarOutside] = useState<boolean>(false);
	const [selectedFilesAdditionals, setSelectedFilesAdditionals] = useState<Array<any>>([]);
	const [submited, setSubmited] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [arrayCulture, setArrayCulture] = useState<CultureResults[]>([]);
	const [arrayEvents, setArrayEvents] = useState<EventsResult[]>([]);
	const [breeders, setBreeders] = useState<BreederResults[]>([]);
	const [applicants, setApplicants] = useState<ApplicantResults[]>([]);
	const [specieName, setSpecieName] = useState<string>('');
	const [selectedEvent, setSelectedEvent] = useState<EventsResult>();
	const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
	const [models, setModels] = useState<ModelFilesProtection>();
	const [cultureDetails, setCultureDetails] = useState<CultureResults>();
	const [loadingNeededData, setLoadingNeededData] = useState<boolean>(true);
	const [errorGetNeededData, setErrorGetNeededData] = useState<boolean>(false);
	const [loadingRequestData, setLoadingRequestData] = useState<boolean>(true);
	const [fileField1, setFileField1] = useState<ModelFileRegister>();
	const [fileField2, setFileField2] = useState<ModelFileRegister>();
	const [fileField3, setFileField3] = useState<ModelFileRegister>();
	const [fileField4, setFileField4] = useState<ModelFileRegister>();
	const [fileFieldProcuracao, setFileFieldProcuracao] = useState<ModelFileRegister>();
	const [fileFieldRelatorioTecnico, setFileFieldRelatorioTecnico] = useState<ModelFileRegister>();
	const [fileFieldDescritores, setFileFieldDescritores] = useState<ModelFileRegister>();
	const [lblProcurement, setLblProcurement] = useState('Escolher arquivo');
	const [lblDescriptor, setLblDescriptor] = useState('Escolher arquivo');
	const [status, setStatus] = useState<Status>({});
	const [protocolo, setProtocolo] = useState('');
	const [dataProtocolo, setDataProtocolo] = useState<Date>();
	const [registro, setRegistro] = useState('');
	const [dataRegistro, setDataRegistro] = useState<Date>();
	const [dataTerminoRegistro, setDataTerminoRegistro] = useState<Date>();

	const [selectedArquivosRelatorioTecnico, setSelectedArquivosRelatorioTecnico] = useState<ArquivosRelatorioTecnico[]>([]);
	const [modalArquivoRelatorioTecnico, setModalArquivoRelatorioTecnico] = useState<boolean>(false);
	const [arquivoRelatorioTecnicoID, setArquivoRelatorioTecnicoID] = useState<any>();
	const [statusArquivoRelatorio, setStatusArquivoRelatorio] = useState<Status>({});
	const [msgRecusaModalArquivoRelatorioTecnico, setMsgRecusaModalArquivoRelatorioTecnico] = useState('');

	const [selectedArquivosDescritores, setSelectedArquivosDescritores] = useState<ArquivosDescritores[]>([]);
	const [modalArquivoDescritores, setModalArquivoDescritores] = useState<boolean>(false);
	const [arquivoDescritoresID, setArquivoDescritoresID] = useState<any>();
	const [statusArquivoDescritores, setStatusArquivoDescritores] = useState<Status>({});
	const [msgRecusaModalArquivoDescritores, setMsgRecusaModalArquivoDescritores] = useState('');

	const conditionalModality = transferredCultivar ? {
		modality: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		modality: yup
			.string()
	}

	const conditionalModifiedOrganism = modifiedOrganism ? {
		cultivarGeneticTransformationEvent: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		cultivarGeneticTransformationEvent: yup
			.string()
	}

	const conditionalDenominationCultivar = cultivarInBrazil ? {
		denominationCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denominationCultivar: yup
			.string()
	}

	const conditionalountryOfCommercialization = cultivarOutside ? {
		countryOfCommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		countryOfCommercialization: yup
			.string()
	}

	const conditionalDenominationOfCommercialization = cultivarOutside ? {
		denominationOfCommercialization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denominationOfCommercialization: yup
			.string()
	}

	const conditionalSpecificModality = neededSpecifyModality ? {
		specificModality: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		specificModality: yup
			.string()
	}

	const conditionalIntergovernametalOrganization = registerCultivarProtection &&
		cultivarProtection ? {
		intergovernametalOrganization: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		intergovernametalOrganization: yup
			.string()
	}

	const conditionalTypeOfprotection = registerCultivarProtection &&
		cultivarProtection ? {
		typeOfprotection: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		typeOfprotection: yup
			.string()
	}

	const conditionalProtocolNumber = registerCultivarProtection &&
		cultivarProtection ? {
		protocolNumber: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		protocolNumber: yup
			.string()
	}

	const conditionalDenomination = registerCultivarProtection &&
		cultivarProtection ? {
		denomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED)
	} : {
		denomination: yup
			.string()
	}

	const protectionSNPCSchema = yup.object().shape({
		scientificName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		speciesName: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		proposedDenomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		experimentalDenomination: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalModality,
		...conditionalSpecificModality,
		modalityDocument: yup
			.mixed(),
		countryOfCultivar: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		...conditionalModifiedOrganism,
		...conditionalIntergovernametalOrganization,
		...conditionalTypeOfprotection,
		date: yup
			.date(),
		...conditionalProtocolNumber,
		...conditionalDenomination,
		...conditionalDenominationCultivar,
		dateOfFirstCommercialization: yup
			.date(),
		commercializationFile: yup
			.mixed(),
		...conditionalountryOfCommercialization,
		...conditionalDenominationOfCommercialization,
		dateOfCommercialization: yup
			.date(),
		sampleAddress: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		contactPersonInfo: yup
			.string()
			.required(STRINGS.FORM_FIELD_REQUIRED),
		procurementReport: yup
			.mixed(),
		descriptorsTable: yup
			.mixed(),
		fileType: yup
			.string(),
		additionalFiles: yup
			.mixed(),
	})

	const { register, errors, control, reset } = useForm({
		mode: 'onTouched',
		resolver: yupResolver(protectionSNPCSchema)
	})

	const GetRowNodeId = (node: any): any => {
		return node?.id;
	}

	useEffect(() => {
		const getProtectionList = async () => {
			try {
				let resultCulture = await api.get<CultureResults[]>(`api/v1/cultivares/Cultura/obterporfiltro?Ativo=true`);
				let restulEvents = await api.get<EventsResult[]>(`api/v1/cultivares/Evento/obterporfiltro?Ativo=true`);

				let responseBreeders = await api.get<BreederResults[]>('api/v1/cultivares/EquipeTecnicaMelhorista/obterporfiltro?Ativo=true');
				let responseApplicants = await api.get<ApplicantResults[]>('api/v1/cultivares/Requerente/obterporfiltro?Ativo=true');

				if (resultCulture.status === 200)
					setArrayCulture(resultCulture.data);
				if (restulEvents.status === 200)
					setArrayEvents(restulEvents.data);
				if (responseBreeders.status === 200) {
					setBreeders(responseBreeders.data);
				}
				if (responseApplicants.status === 200) {
					setApplicants(responseApplicants.data);
				}
			} catch (error:any) {
				setError(true);
			}
			setLoadingNeededData(false);
		}

		const getRequest = async () => {
			try {
				const { data } = await api.get<RequestProtectionSNPC>(`api/v1/cultivares/CultivaresProtecao/${location.state.id}`);

				if (!loadingNeededData) {
					reset({
						speciesName: data.culturaId,
						scientificName: data.nomeCientificoDaEspecie,
						proposedDenomination: data.denominacaoProposta,
						experimentalDenomination: data.denominacaoExperimental,
						selectedModality: data.tipoModalidade,
						specificModality: data.outraEspecificar,
						countryOfCultivar: data.paisCultivarObtida,
						intergovernametalOrganization: data.paisOrganizacao,
						protocolNumber: data.numeroProtocolo,
						denomination: data.denominacao,
						denominationCultivar: data.denominacaoVendaComercializacao,
						dateOfFirstCommercialization: data.dataDaPrimeiraComercializacao ? moment(data.dataDaPrimeiraComercializacao).toDate() : null,
						countryOfCommercialization: data.pais,
						denominationOfCommercialization: data.denominacaoOferecidaComercializacaoNoExeterior,
						dateOfCommercialization: data.datePrimeiraComercializacaoNoExterior ? moment(data.datePrimeiraComercializacaoNoExterior).toDate() : null,
						sampleAddress: data.enderecoOndeAmostraVivaSeraMantida,
						contactPersonInfo: data.pessoaDeContato,
						date: data.data ? moment(data.data).toDate() : null,
						generalObservations: data.observacoes
					})
					setCodigoRequisicao(data.codigo);
					setSpecieName(data.nomeComumDaEspecie);
					setCultivarOutside(data.cultivarFoiOferecidaComercializacaoNoExeterior);
					setTransferredCultivar(data.cultivarTransferida);
					setModifiedOrganism(data.cultivarGeneticamenteModificada);
					setCultivarProtection(data.protecaoDaCultivarFoiSolicitadaNoExterior);
					setCultivarInBrasil(data.cultivarOferecidaVendaComercializadaBrasil);
					setSelectedEvent({
						id: data.eventoId,
						nomeEvento: data.nomeDoEvento,
					});
					getCultureById(Number(data.culturaId));
					if (data.cultivaresRequerentes.length) {
						const allApplicants = data.cultivaresRequerentes.map(req => {
							return req.requerente;
						})
						setSelectedApplicants(allApplicants);
						setInsertApplicants(true);
					}
					if (data.cultivaresProtecaoEquipeTecnicas.length) {
						const allBreeders = data.cultivaresProtecaoEquipeTecnicas.map(melhorista => {
							return melhorista.equipeTecnicaMelhorista;
						});
						setSelectedBreeder(allBreeders);
						setInsertBreeder(true);
					}

					if (data.arquivosRelatorioTecnico) {
						setSelectedArquivosRelatorioTecnico(data.arquivosRelatorioTecnico)

						for (let i = 0, t = data.arquivosRelatorioTecnico.length; i < t; i++) {
							let relatorioTecnico = data.arquivosRelatorioTecnico[i];
							let arquivoRelatorioTecnico: ModelFileRegister =
							{
								id: relatorioTecnico.arquivo.id,
								codigo: relatorioTecnico.arquivo.codigo,
								path: relatorioTecnico.arquivo.path,
								nome: relatorioTecnico.arquivo.nome,
								usuario: relatorioTecnico.arquivo.usuario,
								dataAtualizacao: relatorioTecnico.arquivo.dataAtualizacao,
							};

							setFileField3(arquivoRelatorioTecnico);
						}
					}

					if (data.arquivosDescritores) {
						setSelectedArquivosDescritores(data.arquivosDescritores)
						for (let i = 0, t = data.arquivosDescritores.length; i < t; i++) {
							let Descritores = data.arquivosDescritores[i];
							let arquivoDescritores: ModelFileRegister =
							{
								id: Descritores.arquivo.id,
								codigo: Descritores.arquivo.codigo,
								path: Descritores.arquivo.path,
								nome: Descritores.arquivo.nome,
								usuario: Descritores.arquivo.usuario,
								dataAtualizacao: Descritores.arquivo.dataAtualizacao,
							};

							setFileField4(arquivoDescritores);
						}
					}

					setModalArquivoRelatorioTecnico(false);
					setModalArquivoDescritores(false);

					if (data.arquivoAnexoVendaComercializacao)		setFileField2(data.arquivoAnexoVendaComercializacao);
					if (data.arquivoDocumentoComprobatorio)			setFileField1(data.arquivoDocumentoComprobatorio);
					if (data.arquivoRelatorioTecnicoDeObtencao)		setFileField3(data.arquivoRelatorioTecnicoDeObtencao);
					if (data.arquivoTabelaDescritores)				setFileField4(data.arquivoTabelaDescritores);
					if (data.arquivoConclusao)						setFileFieldConclusao(data.arquivoConclusao);
					if (data.arquivoProcuracao)						setFileFieldProcuracao(data.arquivoProcuracao);
						
					if (data.arquivosAnexos) {
						const allFiles = data.arquivosAnexos.map(file => {
							return file;
						})
						setSelectedFilesAdditionals(allFiles);
					}
					if (data.paisOrganizacao) {
						setProtectionOfCultivarItems([...protectionOfCultivarItems, {
							intergovernametalOrganization: data.paisOrganizacao,
							typeOfprotection: data.tipoDeProtecao,
							date: moment(data.data).toDate(),
							protocolNumber: data.numeroProtocolo,
							denomination: data.denominacao,
						}])
					}
					setStatus({
						status: data.status,
						statusDescricao: data.statusDescricao
					})
					setRegistro(data.registro);
					if (data.dataRegistro) {
						setDataRegistro(moment(data.dataRegistro).toDate());
					}
					if (data.dataTerminoRegistro) {
						setDataTerminoRegistro(moment(data.dataTerminoRegistro).toDate());
					}
					if (data.dataProtocolo)
						setDataProtocolo(moment(data.dataProtocolo).toDate());
					setProtocolo(data.protocolo);
				}
			} catch (error:any) {
				setErrorGetNeededData(true);
			}

			setTimeout(() => {
				setLoadingRequestData(false);
			}, 2500)
		}
		getProtectionList();
		getRequest();
	}, [loadingNeededData, location.state.id, reset]);

	const downloadModelFile = async (file: ModelFileRegister | undefined) => {
		if (file != undefined) {
			setLoadingDownload(true);
			try {
				apiFileDownload.get(`api/v1/cultivares/Download?codigo=${file.codigo}`)
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.nome); //or any other extension
						document.body.appendChild(link);
						link.click();
					});

			} catch (error:any) {
				console.error(error);
			}
			setLoadingDownload(false);
		}
	}

	const getCultureById = async (idCulture: number) => {
		try {
			const { data, status } = await api.get<CultureResults>(`api/v1/cultivares/Cultura/${Number(idCulture)}`);
			if (status === 200) {
				setCultureDetails(data);
				if (data.modeloProtecaoCultivares !== null) {
					setModels(data.modeloProtecaoCultivares);
				}
			}
		} catch (error:any) {
			console.error(error);
		}
	}

	const [fileFieldConclusao, setFileFieldConclusao] = useState<ModelFileRegister>();

	const actionArquivosRelatorioTecnico = (props: any) => {
		return <EditButton
			onClick={() => {
				setArquivoRelatorioTecnicoID(props.data.id)
				setStatusArquivoRelatorio(props.data.status)
				setMsgRecusaModalArquivoRelatorioTecnico(props.data.motivoRecusa)

				let arquivo = props.data.arquivo;
				let arquivoRelatorioTecnico: ModelFileRegister =
				{
					id: arquivo.id,
					codigo: arquivo.codigo,
					path: arquivo.path,
					nome: arquivo.nome,
					usuario: arquivo.usuario,
					dataAtualizacao: arquivo.dataAtualizacao,
				};

				setFileFieldRelatorioTecnico(arquivoRelatorioTecnico);
				setModalArquivoRelatorioTecnico(true)
			}}
		/>;
	}

	const actionArquivosDescritores = (props: any) => {
		return <EditButton
			onClick={() => {
				setArquivoDescritoresID(props.data.id)
				setStatusArquivoDescritores(props.data.status)
				setMsgRecusaModalArquivoDescritores(props.data.motivoRecusa)

				let arquivo = props.data.arquivo;
				let arquivoDescritores: ModelFileRegister =
				{
					id: arquivo.id,
					codigo: arquivo.codigo,
					path: arquivo.path,
					nome: arquivo.nome,
					usuario: arquivo.usuario,
					dataAtualizacao: arquivo.dataAtualizacao,
				};

				setFileFieldDescritores(arquivoDescritores);

				setModalArquivoDescritores(true)
			}}
		/>;
	}

	return <div className="container container-fluid">
		<h3>Visualização de proteção snpc</h3>
		<If condition={loadingRequestData}>
			<span className="loading-page">
				<Loading />
			</span>
		</If>
		<If condition={!loadingRequestData}>
			<Form>
				<Fieldset title="Proteção">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Código
							</Form.Label>
							<Form.Control
								value={codigoRequisicao}
								disabled
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Status
							</Form.Label>
							<Form.Control
								value={status.statusDescricao}
								disabled
								readOnly
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="1. Requerente">
					<Form.Row className="row-custom">
						<Form.Group className="label-btn">
							<Form.Label>
								Requerente
							</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={insertApplicants && selectedApplicants.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedApplicants}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								rowSelection={'multiple'}
							>
								<AgGridColumn
									headerName='Requerente'
									field="nome"
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>
				<Fieldset title="2. Táxon">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nome comum da espécie
							</Form.Label>
							<Form.Control
								as="select"
								ref={register}
								name="speciesName"
								disabled
							>
								<Form.Control
									as="option"
									label="Escolha uma opção"
								/>
								{arrayCulture.map(item => (
									<Form.Control
										as="option"
										label={item.nome}
										value={item.id}
										key={item.id}
									/>
								))}
							</Form.Control>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Nome científico da espécie
							</Form.Label>
							<Form.Control
								ref={register}
								name="scientificName"
								autoComplete="off"
								placeholder="Nome científico da espécie"
								readOnly
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="3. Cultivar">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Denominação proposta
							</Form.Label>
							<Form.Control
								ref={register}
								name="proposedDenomination"
								placeholder="Insira a denominação proposta"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Denominação experimental ou pré-comercial
							</Form.Label>
							<Form.Control
								ref={register}
								name="experimentalDenomination"
								placeholder="Insira a denominação experimental ou pré-comercial"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="4. Obtenção da cultivar">
					<If condition={insertBreeder && selectedBreeder.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedBreeder}
								defaultColDef={{ flex: 1, sortable: true }}
							>
								<AgGridColumn
									headerName='Melhorista'
									field='nome'
								/>
							</AgGridReact>
						</div>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="transferredCultivar">
							<div className="option-cultivar">
								<span>Cultivar transferida?<em>*</em></span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									disabled
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									disabled
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={transferredCultivar}>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={6}>
								<Form.Label>
									Modalidade
								</Form.Label>
								<Form.Control
									as="select"
									ref={register}
									name="modality"
									disabled
								>
									{protectionSNPC.modalitys.map((item, index) => (
										<Form.Control
											key={index}
											as="option"
											label={item.modality}
											value={item.id}
										/>
									))}
								</Form.Control>
							</Form.Group>
							<If condition={neededSpecifyModality}>
								<Form.Group as={Col}>
									<Form.Label>
										Especificar a modalidade
									</Form.Label>
									<Form.Control
										ref={register}
										name="specificModality"
										placeholder="Insira a modalidade"
										autoComplete="off"
										disabled
									/>
								</Form.Group>
								<Form.Group as={Col} md={6}>
									<Form.Label>
										Documento comprobatório
									</Form.Label>
								</Form.Group>
								<If condition={fileField1}>
									<FileExistent text={fileField1?.nome} date={fileField1?.dataAtualizacao}
										onDownload={(e: any) => {
											e.preventDefault();
											downloadModelFile(fileField1);
										}}
									/>
								</If>
							</If>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={6}>
							<Form.Label>
								País em que a cultivar foi obtida
							</Form.Label>
							<Form.Control
								ref={register}
								name="countryOfCultivar"
								placeholder="Insira o país em que a cultivar foi obtida"
								disabled
								autoComplete="off"
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="modifiedOrganism">
							<div className="option-cultivar">
								<span>Cultivar geneticamente modificada?<em>*</em></span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									disabled
									checked={modifiedOrganism}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									disabled
									checked={!modifiedOrganism}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={modifiedOrganism}>
							<Form.Group as={Col}>
								<Form.Label>
									Nome do evento
								</Form.Label>
								<Form.Control
									as="select"
									ref={register}
									name="cultivarGeneticTransformationEvent"
									title={selectedEvent?.nomeEvento}
								>
									<Form.Control
										as="option"
										label={selectedEvent?.nomeEvento}
										value={selectedEvent?.id}
									/>
								</Form.Control>
							</Form.Group>
						</If>
					</Form.Row>
				</Fieldset>
				<Fieldset title="5. Proteção da cultivar">
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="cultivarProtection">
							<div className="option-cultivar">
								<span>A proteção da cultivar foi solicitada anteriormente no exterior?</span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									disabled
									checked={cultivarProtection}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									disabled
									checked={!cultivarProtection}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={cultivarProtection}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País/Organização Intergovernamental
								</Form.Label>
								<Form.Control
									ref={register}
									name="intergovernametalOrganization"
									placeholder="Insira o país/organização governamental"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Tipo de proteção
								</Form.Label>
								<Form.Control
									as="select"
									ref={register}
									name="typeOfprotection"
									disabled
								>
									{protectionSNPC.typeOfProtection.map(item => (
										<Form.Control
											as="option"
											key={item.id}
											label={item.type}
											value={item.type}
										/>
									))}
								</Form.Control>
								<If condition={errors.typeOfprotection}>
									<Form.Control.Feedback type="invalid">
										{errors.typeOfprotection?.message}
									</Form.Control.Feedback>
								</If>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Data
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="date"
									render={({ onChange, value }) => (
										<DatePicker
											disabled
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Número de protocolo
								</Form.Label>
								<Form.Control
									ref={register}
									name="protocolNumber"
									placeholder="Insira o número do protocolo"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Denominação
								</Form.Label>
								<Form.Control
									ref={register}
									name="denomination"
									placeholder="Insira a denominação"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>
				<Fieldset title="6. Primeira comercialização da cultivar">
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="cultivarInBrazil">
							<div className="option-cultivar">
								<span>A cultivar foi oferecida à venda ou comercializada no Brasil?</span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									disabled
									checked={cultivarInBrazil}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									disabled
									checked={!cultivarInBrazil}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={cultivarInBrazil}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									Denominação
								</Form.Label>
								<Form.Control
									ref={register}
									name="denominationCultivar"
									placeholder="Insira a denominação da cultivar"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data da primeira comercialização
								</Form.Label>
								<br />
								<Controller
									disabled
									control={control}
									name="dateOfFirstCommercialization"
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
						<Form.Row className="row-custom">
							<Form.Group as={Col} md={7}>
								<Form.Label>
									Anexar 1ª nota fiscal de comercialização da cultivar
								</Form.Label>
							</Form.Group>
							<If condition={fileField2}>
								<FileExistent text={fileField2?.nome} date={fileField2?.dataAtualizacao}
									onDownload={(e: any) => {
										e.preventDefault();
										downloadModelFile(fileField2);
									}}
								/>
							</If>
						</Form.Row>
					</If>
					<Form.Row className="row-custom">
						<Form.Group as={Col} controlId="cultivarOutside">
							<div className="option-cultivar">
								<span>A cultivar foi oferecida à venda ou comercializada no Exterior?</span>
								<Form.Check
									value="sim"
									type="radio"
									aria-label="radio 1"
									label="Sim"
									disabled
									checked={cultivarOutside}
								/>
								<Form.Check
									value="não"
									type="radio"
									aria-label="radio 2"
									label="Não"
									disabled
									checked={!cultivarOutside}
								/>
							</div>
						</Form.Group>
					</Form.Row>
					<If condition={cultivarOutside}>
						<Form.Row className="row-custom">
							<Form.Group as={Col}>
								<Form.Label>
									País<em>*</em>
								</Form.Label>
								<Form.Control
									ref={register}
									name="countryOfCommercialization"
									placeholder="Insira o país"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Denominação
								</Form.Label>
								<Form.Control
									ref={register}
									name="denominationOfCommercialization"
									placeholder="Insira a denominação"
									autoComplete="off"
									disabled
								/>
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>
									Data da primeira comercialização
								</Form.Label>
								<br />
								<Controller
									control={control}
									name="dateOfCommercialization"
									render={({ onChange, value }) => (
										<DatePicker
											disabled
											selected={value}
											onChange={onChange}
											className="input-custom-to-date"
											locale="pt-BR"
											dateFormat="dd/MM/yyyy"
											placeholderText="Selecionar a data"
										/>
									)}
								/>
							</Form.Group>
						</Form.Row>
					</If>
				</Fieldset>
				<Fieldset title="7. Declaração de amostra viva">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Endereço onde a amostra viva será mantida
							</Form.Label>
							<Form.Control
								ref={register}
								name="sampleAddress"
								placeholder="Insira o endereço da amostra viva"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Pessoa de contato &#40;nome, e-mail e telefone&#41;
						</Form.Label>
							<Form.Control
								ref={register}
								name="contactPersonInfo"
								placeholder="Insira o nome da pessoa de contato"
								autoComplete="off"
								disabled
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>
				<Fieldset title="8. Relatório técnico de obtenção">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Relatório técnico
							</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={!models?.modeloRelatorioTecnicoObtencao}>
								<NotAvailableModel />
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField3?.id}>
							<FileExistent text={fileField3?.nome} date={fileField3?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField3);
								}}
							/>
						</If>
					</Form.Row>
					<If condition={selectedArquivosRelatorioTecnico.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedArquivosRelatorioTecnico}
								deltaRowDataMode={true}
								getRowNodeId={GetRowNodeId}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								frameworkComponents={{
									action: actionArquivosRelatorioTecnico
								}}
							>
								<AgGridColumn
									headerName='Arquivo'
									field="arquivo.nome"
									minWidth={800}
								/>
								<AgGridColumn
									headerName='Status'
									field="statusDescricao"
								/>
								<AgGridColumn
									headerName="Ação"
									cellRenderer="action"
								/>
							</AgGridReact>
						</div>
					</If>
					<If condition={modalArquivoRelatorioTecnico}>
						<Modal
							show={true}
							onHide={() => setModalArquivoRelatorioTecnico(false)}
							title="Arquivo de Relatório Técnico"
						>
							<Form autoComplete="off">
								<Form.Row className="row-custom">
									<Form.Group as={Col}>
										<FileExistent text={fileFieldRelatorioTecnico?.nome} date={fileFieldRelatorioTecnico?.dataAtualizacao}
											onDownload={(e: any) => {
												e.preventDefault();
												downloadModelFile(fileFieldRelatorioTecnico);
											}}
											onClick={() => {
												setFileFieldRelatorioTecnico({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
											}}
										/>
									</Form.Group>
								</Form.Row>
								<If condition={
									(
										statusArquivoRelatorio === STATUS_PROTECTION_ARCHIVE.Recusado
									)
								}>
									<Form.Row className="row-custom">
										<Form.Group as={Col}>
											<Form.Label>
												Motivo da recusa
											</Form.Label>
											<Form.Control
												disabled
												as="textarea"
												ref={register}
												name="msgRecusaModalArquivoRelatorioTecnico"
												defaultValue={msgRecusaModalArquivoRelatorioTecnico}
												type="text"
												rows={5}
											/>
										</Form.Group>
									</Form.Row>
								</If>
								<div className="button-position">
									<Button
										title="Cancelar"
										contained={false}
										type="button"
										onClick={() => setModalArquivoRelatorioTecnico(false)}
									/>
								</div>
							</Form>
						</Modal>
					</If>

				</Fieldset>
				<Fieldset title="9. Tabela de descritores">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Tabela de descritores
							</Form.Label>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<If condition={!models?.modeloTabelaDescritores}>
								<NotAvailableModel />
							</If>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileField4?.id}>
							<FileExistent text={fileField4?.nome} date={fileField4?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileField4);
								}}
							/>
						</If>
					</Form.Row>
					<If condition={selectedArquivosDescritores.length}>
						<div className="ag-theme-alpine" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedArquivosDescritores}
								deltaRowDataMode={true}
								getRowNodeId={GetRowNodeId}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
								frameworkComponents={{
									action: actionArquivosDescritores
								}}
							>
								<AgGridColumn
									headerName='Arquivo'
									field="arquivo.nome"
									minWidth={800}
								/>
								<AgGridColumn
									headerName='Status'
									field="statusDescricao"
								/>
								<AgGridColumn
									headerName="Ação"
									cellRenderer="action"
								/>
							</AgGridReact>
						</div>
					</If>
					<If condition={modalArquivoDescritores}>
						<Modal
							show={true}
							onHide={() => setModalArquivoDescritores(false)}
							title="Arquivo de Tabela de Descritores"
						>
							<Form autoComplete="off">
								<Form.Row className="row-custom">
									<Form.Group as={Col}>
										<FileExistent text={fileFieldDescritores?.nome} date={fileFieldDescritores?.dataAtualizacao}
											onDownload={(e: any) => {
												e.preventDefault();
												downloadModelFile(fileFieldDescritores);
											}}
											onClick={() => {
												setFileFieldDescritores({ id: null, codigo: '', dataAtualizacao: '', nome: '', path: '', usuario: '' });
											}}
										/>
									</Form.Group>
								</Form.Row>
								<If condition={
									(
										statusArquivoDescritores === STATUS_PROTECTION_ARCHIVE.Recusado
									)
								}>
									<Form.Row className="row-custom">
										<Form.Group as={Col}>
											<Form.Label>
												Motivo da recusa
											</Form.Label>
											<Form.Control
												disabled
												as="textarea"
												ref={register}
												name="msgRecusaModalArquivoDescritores"
												defaultValue={msgRecusaModalArquivoDescritores}
												type="text"
												rows={5}
											/>
										</Form.Group>
									</Form.Row>
								</If>
								<div className="button-position">
									<Button
										title="Cancelar"
										contained={false}
										type="button"
										onClick={() => setModalArquivoDescritores(false)}
									/>
								</div>
							</Form>
						</Modal>
					</If>

				</Fieldset>
				<Fieldset title="10. Anexos complementares">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={8}>
							<Form.Label>
								Anexo complementar
						</Form.Label>
						</Form.Group>
					</Form.Row>
					<If condition={selectedFilesAdditionals.length}>
						<div className="ag-theme-alpine ag-cell-custom" style={{ height: '200px', width: '100%' }}>
							<AgGridReact
								rowData={selectedFilesAdditionals}
								defaultColDef={{ flex: 1, sortable: true }}
								suppressRowClickSelection={true}
							>
								<AgGridColumn
									headerName="Arquivo"
									valueGetter={({ data }: any) => data.arquivo.nome}
								/>
							</AgGridReact>
						</div>
					</If>
				</Fieldset>

				<Fieldset title="11. Protocolo">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={4}>
							<Form.Label>
								Número Protocolo
							</Form.Label>
							<Form.Control
								disabled
								value={protocolo}
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>
								Data Protocolo
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="protocolDate"
								render={({ onChange, value }) => (
									<DatePicker
										readOnly
										selected={dataProtocolo}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
									/>
								)}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				<Fieldset title="12. Registro MAPA">
					<Form.Row className="row-custom">
						<Form.Group as={Col} md={12}>
							<Form.Label>
								Número do Registro
								</Form.Label>
							<Form.Control
								disabled
								value={registro}
								type="text"
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Data de Início da Proteção
								</Form.Label>
							<br />
							<Controller
								control={control}
								name="dateRegistro"
								render={({ onChange, value }) => (
									<DatePicker
										readOnly
										selected={dataRegistro}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
									/>
								)}
							/>
						</Form.Group>
						<Form.Group as={Col} md={6}>
							<Form.Label>
								Data de Término da Proteção
							</Form.Label>
							<br />
							<Controller
								control={control}
								name="dateTerminoRegistro"
								render={({ onChange, value }) => (
									<DatePicker
										readOnly
										selected={dataTerminoRegistro}
										onChange={onChange}
										className="input-custom-to-date"
										locale="pt-BR"
										dateFormat="dd/MM/yyyy"
									/>
								)}
							/>
						</Form.Group>
						<If condition={!!fileFieldConclusao?.id}>
						<FileExistent
							label="Arquivo de Proteção SNPC"
							text={fileFieldConclusao?.nome}
							date={fileFieldConclusao?.dataAtualizacao}
							onDownload={(e: any) => {
								e.preventDefault();
								downloadModelFile(fileFieldConclusao);
							}}
						/>
					</If>
					</Form.Row>
				</Fieldset>

				<Fieldset title="13. Procuração">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<If condition={loadingDownload}>
								<LoadingInForm />
							</If>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<If condition={!!fileFieldProcuracao?.id}>
							<FileExistent text={fileFieldProcuracao?.nome} date={fileFieldProcuracao?.dataAtualizacao}
								onDownload={(e: any) => {
									e.preventDefault();
									downloadModelFile(fileFieldProcuracao);
								}}
							/>
						</If>
					</Form.Row>
				</Fieldset>

				<Fieldset title="14. Observações Gerais">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Control
								as="textarea"
								ref={register}
								name="generalObservations"
								type="text"
								placeholder="Insira as observações gerais"
								maxLength={4000}
								disabled
								rows={5}
							/>
						</Form.Group>
					</Form.Row>
				</Fieldset>

				<div className="button-position">
					<Button
						title="Voltar"
						type="button"
						contained={false}
						onClick={() => history.goBack()}
					/>
				</div>
			</Form>
		</If>
	</div>;
}