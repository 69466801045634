import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ViewButton from '../../components/ViewButton';
import Modal from '../../components/Modal';
import If from '../../components/If';

import TextField 	from  "../../components/form/TextField";
import {EditField } from  "../../components/page/EditView";
import EditBox from  "../../components/page/EditBox";

import { useForm } from "react-hook-form";
import RCLpmaService, { RolesService } from "./services";
import LpmaService, * as lpmaServ from "../lpma/services";
import LpmaComunicadosService from "../lpma/services";
import OficioCIBioService from "../lpma/services";
import { STRINGS_RCLPMA } from "../../util/rclpma/string";
import RichText 	from "../../components/form/RichText";
import ToggleBox 	from "../../components/form/ToggleBox";
import Tabs 	from  "../../components/form/Tabs";
import React, { Fragment, useContext,  useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { STATUS, STATUS_RC_LPMA } from "../../util/lpma/status";

import DropDown 	from "../../components/form/DropDown";
import Button 		from "../../components/Button";
import GenericButton from '../../components/FlowGenericButton';
import { isNumber } from "../../util/jsextends";
import { uuid } from "../../services/uuid";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SearchBtnDialog from "../../components/form/SearchBtnDialog";
import * as dto from "../../models/modelsRCLPMA";
import * as lpma from "../../models/modelsLPMA";
import * as ami from "../../models/modelsAuthorizationsCIBIO";
import { listEnumItem, ParamsType, StateType } from "../../models/modelCommum";
import DateField from "../../components/form/DateField";
import {
	LpmaRNEnsaio, LpmaLocal, LpmaRNEnsaioTratamento, LpmaRNEnsaioAvaliacaoAgronomica,
	LpmaPotenciaisConstrucoes, LPMA_GenesInteresseGrid, OficioCIBIO,
	Lpma_RN06_Comunicados,
	Lpma_RN06_Comunicados_Datas,
	Lpma_RN06_Comunicados_Materiais_Aprovados,
	Lpma_RN06_Comunicados_Materiais_Plantados,
} from "../../models/modelsLPMA";

import * as EditButtons from "../../components/EditButton";
import RadioBox from "../../components/form/RadioBox";
import { ListCol } from "../../components/page/ListingView";
import { _generateDate } from "../../util/convert";

import { Form, Col} from "react-bootstrap";
import moment from "moment";
import TextFields, { Field as TField } from "../../components/form/TextFields";
import Grid from "../../components/form/Grid";
import FilesUpload from "../../components/form/FilesUpload";
import DataHide, { LabelArea } from "../../components/form/DataHide";
import { DialogEditSingle, DialogPopUp } from "../../components/form/DialogEdit";
import CheckBox from "../../components/form/CheckBox";
import Loading from "../../components/Loading";
import { STRINGS } from "../../util/strings";

import api, { apiFileDownload } from '../../services/api';
import { isNullOrUndefined } from 'util';

import { DecimalAsComma } from '../NumberFormat';


interface IInitData{
	equipeTecnica		: lpma.LpmaEquipeTecnica[],
	locais				: LpmaLocal[],
	projetos			: lpma.LpmaProjeto[],
	itemsTipoTratamento	: any[],
	autDataDefault		: any[],
	locations 			: any[],
	events    			: ami.EventResult[],
	cultures  			: any[],
	material  			: ami.Enum[],
	unidadeMedida 		: ami.Enum[],
	transporte 				: ami.Enum[],
	configLpma				: lpma.LpmaConfigucaoes[];
	potenciaisConstrucoes06	: lpma.LpmaPotenciaisConstrucoes[];
	potenciaisConstrucoes35 : lpma.LpmaPotenciaisConstrucoes[];
//	potenciaisConstrucoesLPMA: any[];
}

function spanBold({children}:any){
	return <span style={{fontWeight: 'bolder'}}>{children}</span>;
}

export default function Edit (){
	const RCLpmaServiceApi 	= new RCLpmaService();
	const RCLpmaRoles		= new RolesService(useContext);
	const LPMAServiceApi	= new LpmaService();
	const ComunicadosServiceApi = new LpmaComunicadosService();

	const UrlParam 	= useParams<ParamsType>();
	const location 	= useLocation<StateType>();
	const history 	= useHistory();
	const goUrl = (url: string)=> {
		history.push(url);
	}

	const [entity, 		setEntity] = useState<dto.RCLpmaDto>();
	const [amiBase, 	setAmiBase]  = useState<ami.AMIByIdToEdit>();
	const [amiEventos, 	setAmiEventos]  = useState<any[]>();
	const [eventListAMI, setEventListAMI] = useState<boolean>(true);

	const [entityComunicado, setEntityComunicado] = useState<Lpma_RN06_Comunicados>();
	const [entityComunicadoData, setEntityComunicadoData] = useState<Lpma_RN06_Comunicados_Datas[]>([]);
	const [entityComunicadoMaterialAprovado, setEntityComunicadoMaterialAprovado] = useState<Lpma_RN06_Comunicados_Materiais_Aprovados[]>([]);
	const [entityComunicadoMaterialPlantado, setEntityComunicadoMaterialPlantado] = useState<Lpma_RN06_Comunicados_Materiais_Plantados[]>([]);

	const [entityComunicadoDataGrid, setEntityComunicadoDataGrid] = useState<Lpma_RN06_Comunicados_Datas[]>([]);
	const [entityComunicadoMaterialAprovadoGrid, setEntityComunicadoMaterialAprovadoGrid] = useState<Lpma_RN06_Comunicados_Materiais_Aprovados[]>([]);
	const [entityComunicadoMaterialPlantadoGrid, setEntityComunicadoMaterialPlantadoGrid] = useState<Lpma_RN06_Comunicados_Materiais_Plantados[]>([]);

	const [showSearch, 	setShowSearch] = useState<'AMI' | 'LPMA' | null>(null);

	const [titleForm, 	setTitleForm] = useState<string>(`${STRINGS_RCLPMA.INI_RCLPMA}`);
	
	const [msgClear, 	setMsgClear] = useState<boolean | null>(null);
	const [msgSalvoComSucesso, setMsgSalvoComSucesso] = useState<string>("Solicitação CQB Salvo com Sucesso");
	//const [reloadId, 	setReloadId] = useState<boolean>(false);
	const [loadView, 	setLoadView] = useState<boolean>(true);

	const [initData, 	setInitData] = useState<IInitData>();
	const [autDataDefault, setAutDataDefault] = useState<any>([]);
	const [loadingSubmit, 	setLoadingSubmit] = useState<boolean>(true);
	const [estagio, 		setEstagio] = useState<any>(STATUS.EmPreenchimento);
	const [isCorrecaoForm, 	setIsCorrecaoForm] = useState<boolean>(false);
	const [editarAll, 		setEditarAll] = useState<boolean>(false);
	//const [editarReg, 		setEditarReg] = useState<boolean>(false);
	const [requiredTec, 	setRequiredTec] = useState<boolean>(false);
	const [ensaiosTabIndex, setEnsaiosTabIndex]  = useState<Number>(0);
	const [documentConfig, setDocumentConfig] = useState<listEnumItem[]>([]);
	const [documentConfigList, setDocumentConfigList] = useState<any[]>([]);

	const [itemRemovedEnsaios, setItemRemovedEnsaios] = useState<any[]>([]);
	const [itemRemovedAreas, setItemRemovedAreas] = useState<any[]>([]);

	const [gridProps, setGridProps] = useState<any>(null);
	const [modalGene, setModalGene] = useState<boolean>(false);

	const [geneToView, setGeneToView] = useState<lpma.Lpma_RN_GenesInteresse>({
		id: 0,
		nomeConstrucao: '',
		nomeGeneInteresse: '',
		organismoDoadorGeneInteresse: '',
		funcaoGeneInteresse: '',
		funcaoResumida: '',
	});


	const searchAMIForm = useForm({
		defaultValues: {solicitante:'', dataInicial: null,dataFinal:null, localEnvio: null, localDestino: null, cultura: null, codigo:'' } as any,
	});

	const searchLPMAForm = useForm({
		defaultValues: {lpmarn: null, solicitante:'', projeto: null, tecnicoPrincipal: null, status: null } as any,
	});
	
	const defaultForm = useForm({
		defaultValues: {} as any,
		mode: "onSubmit",
		// resolver: yupResolver(yup.object().shape({
		// 	...((estagio === STATUS.EmAnaliseTecnico) ? {
		// 		observacoes : 		yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		titulo 		: 		yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 	}:{
		// 		...((estagio === STATUS.EmAnalisePeloRegulatorio)?{
		// 			observacoesRegulatorio : 	yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		}:{
		// 			observacoesRegulatorio : 	yup.string(),
		// 		}),
		// 		observacoes : 		yup.string(),
		// 	})
		// })),

		
		// resolver: yupResolver(yup.object().shape({
		// 	// ...((estagio === STATUS.EmAnalisePeloRegulatorio && tipo !== CQB_TIPOS.Cancelamento ) ? {
		// 		// titulo 		: 		yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// projeto 	: 		yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// tecnicoPrincipal : 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// //ensaios		: 		yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// ensaios		: 		yup.array().of(yup.object().shape({
		// 		// 	descricao		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	//croquiFileTemp	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	largura			: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	comprimento		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	parcelaLargura		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	parcelaComprimento		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	densidade		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	numeroRepeticoes		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	percentualSemente		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
				  
		// 		// 	tratamentos: yup.array().of(yup.object().shape({ 
		// 		// 		descricao	: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 		dose		: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 		estagioAplicacao	: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),											

		// 		// 	}))
		// 		// 	.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED},  requer pelo menos 1 tipo de tratamento`)
		// 		// 	.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 tipo de tratamento`),

		// 		// 	avaliacaoAgronomica : yup.array().of(yup.object().shape({ 
		// 		// 		pE11AD1	: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 		descricao	: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),											

		// 		// 	}))
		// 		// 	.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED},  requer pelo menos 1 avaliação agronomica`)
		// 		// 	.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 avaliação agronomica`),

		// 		// }))
		// 		// .nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 ensaio`)
		// 		// .min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 ensaio`),

		// 		// areas : 		yup.array().of(yup.object().shape({
		// 		// 	//descricao	: yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	local 		: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	tecnicoResponsavel : yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	areaEnsaio	: yup.array().min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 area`),
		// 		// }))
		// 		// .nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 area`)
		// 		// .min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 area`),

		// 		// construcoes:  yup.array()					
		// 		// 	.of(yup.object().shape({
		// 		// 	nomeContrucao : yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 		// 	}))
		// 		// 	.nullable().required(`${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 construção`)
		// 		// 	.min(1, `${STRINGS.FORM_FIELD_REQUIRED}, requer pelo menos 1 construção`),
				

		// 	// } : (estagio === STATUS.EmAnalisePeloRegulatorio && tipo === CQB_TIPOS.Cancelamento ) ? {
		// 	// 	tipo	: yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
		// 	// 	itensDaSolicitacao	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 	// 	atividadesRealizadasUltimosMeses	: yup.string().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 	// 	local 	: 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),	
		// 	// 	tecnicoPrincipal : 	yup.object().nullable().required(STRINGS.FORM_FIELD_REQUIRED),
		// 	// 	instalacoes : yup.array().of(yup.object().shape({
		// 	// 		descricao : yup.string().required(STRINGS.FORM_FIELD_REQUIRED),
		// 	// 	}))
		// 	// } : {
		// 		titulo 		:		yup.string(),
		// 		projeto 	: 		yup.object(),
		// 		tecnicoPrincipal : 	yup.object(),
		// 		ensaios		: 		yup.array(),
		// 		areas		:		yup.array(),
		// 		construcoes :		yup.array(),
		// })),
	});

	const OnInit = async (finish: (data:any) => void) =>{
			const equipeTecnica = await LPMAServiceApi.getEquipeTecnica();
			const locais		= await LPMAServiceApi.getLocais();
			const projetos		= await LPMAServiceApi.getProjetos();
			const tpTrat_ 		= await LPMAServiceApi.getEnsaioTipoTratamento();
			const porcFt_ 		= await LPMAServiceApi.getListPorcentagemFitotoxidez();
			const ConfigLpma	= await LPMAServiceApi.getListConfiguracoesLPMA();
			const asDtDefault_	= await RCLpmaServiceApi.getAMIDataDefault();
			const potCons06		= await LPMAServiceApi.getPotenciaisConstrucoes06();
			const potCons35		= await LPMAServiceApi.getPotenciaisConstrucoes35();

			const isNovo = UrlParam.modo === 'nova' || UrlParam.modo === 'novo';
			if(!(!UrlParam) && UrlParam.modo !== 'editar' && !isNovo){
				//!visualizar
				setEditarAll(false);
				setRequiredTec(false);	
			}
			else {
				setEditarAll(true);
				setRequiredTec(false);
			}
			setEstagio(STATUS.EmPreenchimento);

			const initData_ =  {			
				...asDtDefault_,	
				equipeTecnica			: equipeTecnica,
				locais					: locais,
				projetos				: projetos,
				itemsTipoTratamento		: tpTrat_,
				autDataDefault			: asDtDefault_ as any,
				configLpma				: ConfigLpma as lpma.LpmaConfigucaoes[],
				potenciaisConstrucoes06	: potCons06,
				potenciaisConstrucoes35 : potCons35,
			};
			setInitData(initData_ as IInitData);
			setAutDataDefault(asDtDefault_);	
			finish(initData_);
			return initData_;
	}

	React.useEffect(() => {

		setMsgClear(true);     		
		setLoadView(true);
		OnInit((initData_:any) => {
			if (UrlParam && UrlParam.id) {getLoadById(UrlParam.id, initData_);}
			else {
				setLoadView(false);
			}
			
		})		
		setMsgClear(null);   
	}, [])	

	const getReloadByID = (id: any, n_ent:any)=>{ 
		setLoadView(true);
		defaultForm.reset({});
		setTimeout(() => {
			
			getLoadById(id, initData, true); 
			//defaultForm.reset(n_ent);
			//goUrl(`/rclpma/editar/${id}`)
		}, 3500);
		
		// setLoadView(true);
		// getLoadById(id, initData); 
		// setLoadView(false);
	};
	
	const parseButton = (formRef: any) => {		
		let btnVoltar = <Button title="Voltar" contained={false} type="button"
			onClick={() => { history.push(`/rclpma/consultar`) }} />;

		if(!(entity?.lpmaBase) || (!(editarAll || requiredTec))){
			return <div className="button-position">
				{btnVoltar}
			</div>;
		}
		const bntSalvar = (_title_:any)=>  <Button
				title={_title_} contained={true} className="btn-success"
				isLoading={loadingSubmit  && (estagio !== STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => { setEstagio(STATUS.SAVE)}} type="submit" />;

		const isEmAnalise = (!(entity?.status)  || entity?.status === STATUS.EmAnalisePeloRegulatorio || entity?.status === STATUS.EmPreenchimento);

		const lpmaAtiva = !(entity?.statusAtualLpma === STATUS.Cancelado)

		let btnSalvarRascunho = <Fragment />
		let btnAprovar = <Fragment />
		let btnSolicitarCorrecao  = <Fragment />
		let btnSalvarAdmin = <Fragment />
		let btnAprovarAdmin = <Fragment/>

		if(RCLpmaRoles.isRegulatorio() && (entity?.status === STATUS.EmPreenchimento) && lpmaAtiva){
			
			btnSalvarRascunho =<Button
			title="Salvar Rascunho"
			contained={true}
			className="btn-success"
			isLoading={loadingSubmit && (estagio === STATUS.EmPreenchimento)}
			onClick={() => setEstagio(STATUS.EmPreenchimento)}
			type="submit" />

		}

		if(RCLpmaRoles.isRegulatorio() && isEmAnalise === true && lpmaAtiva){
			btnAprovar =<Button
			title="Aprovar Relatório Conclusivo"
			contained={true}
			className="btn-success"
			isLoading={loadingSubmit && (estagio === STATUS.Aprovado)}
			onClick={() => setEstagio(STATUS.Aprovado)}
			type="submit" />

			btnSolicitarCorrecao = 	<GenericButton
			title={ !(entity?.id) ? "Enviar para Análise Técnico" : "Reenviar para Análise Técnico"}
			variant="btn-danger"
			type="submit"
			loading={loadingSubmit && (estagio === STATUS.EmAnaliseTecnico)}
			onClick={() => setEstagio(STATUS.EmAnaliseTecnico)} />;

			// btnAprovar =<Button
			// title={ !(entity?.id) ? "Enviar para Análise Técnico" : "Reenviar para Análise Técnico"}
			// contained={false}
			// className="btn-success"
			// isLoading={loadingSubmit && (estagio === STATUS.Aprovado)}
			// onClick={() => setIsCorrecaoForm(true)}
			// type="button" />

			// btnSolicitarCorrecao = 	<GenericButton
			// title={ !(entity?.id) ? "Enviar para Análise Técnico" : "Reenviar para Análise Técnico"}
			// variant="btn-danger"
			// type="button"
			// loading={loadingSubmit && (estagio === STATUS.EmAnaliseTecnico)}
			// onClick={() => setIsCorrecaoForm(true) } />;
		
		}
		else if(RCLpmaRoles.isTecnico() && entity?.status === STATUS.EmAnaliseTecnico && lpmaAtiva){
				btnAprovar =<Button
				title="Enviar para Análise do Regulatório"
				contained={true}
				className="btn-success"
				isLoading={loadingSubmit && (estagio === STATUS.EmAnalisePeloRegulatorio)}
				onClick={() => setEstagio(STATUS.EmAnalisePeloRegulatorio)}
				type="submit" />
			
		}

		if (RCLpmaRoles.isRegulatorio() && (entity?.status === STATUS.Aprovado) && lpmaAtiva) {

			btnAprovarAdmin = <Button
				title="Aprovar"
				contained={true}
				className="btn-success"
				isLoading={loadingSubmit && (estagio === STATUS.Concluido)}
				onClick={() => setEstagio(STATUS.Concluido)}
				type="submit" />

		}


		if(RCLpmaRoles.isAdmin() && (editarAll || requiredTec) && lpmaAtiva){
			btnSalvarAdmin = <Button
			title="Salvar"
			contained={true}
			className="btn-success"
			isLoading={loadingSubmit  && (estagio === STATUS.SAVE)}
			onClick={() => { setEstagio(STATUS.SAVE)}}
			type="submit"
			/>;
		}
		
		return <div className="button-position">
			{/* {ButtonDialogEnviarTecnico()} */}
			{btnVoltar}
			{btnAprovar}	
			{btnSolicitarCorrecao}	
			{/*{btnAprovarAdmin}*/}
			{btnSalvarAdmin ?? btnSalvarRascunho}						 
		</div>;	
	}

	const submitFormErro = async (errData:any)=>{
		console.log("Erro Data: ", errData)
		setLoadingSubmit(false);
	}

	const submitForm = async (newvalues:any, entity:any) => {
		switch(estagio){
			case STATUS.EmpAprovacao: 
				return await submitFormSaveData('aprovar', STRINGS_RCLPMA.ALERT_STATUS_APROVADA_SUCCESS);
			case STATUS.EmAnaliseTecnico: 
				return await submitFormSaveData('correcao', STRINGS_RCLPMA.ALERT_STATUS_CORRECAO_SUCCESS); 
			case STATUS.EmAnalisePeloRegulatorio: 
				return await submitFormSaveData('salvar_regulatorio', STRINGS_RCLPMA.ALERT_STATUS_REGULATORIO_SUCCESS); 
			case STATUS.Concluido: 
				return await submitFormUpdateStatus('concluir', STRINGS_RCLPMA.ALERT_STATUS_CONCLUIDA_SUCCESS); 
			default:
				return await submitFormSaveData('salvar', STRINGS_RCLPMA.ALERT_MESSAGE_SUCCESS); 
		}
	}

	const submitFormUpdateStatus = async (acao: string, msgSucess: string)  => {
		try {
			setLoadingSubmit(true);
			const getValues = Object.assign(defaultForm.getValues())
			let formData = new FormData();
			formData.append("id", entity?.id);
			formData.append("observacoesRegulatorio", getValues.observacoesRegulatorio ?? "");
			formData.append("observacoes", getValues.observacoes ?? "");

			const newEntity = await RCLpmaServiceApi.getRCLPMAAction(acao, formData);  
			//defaultForm.reset({});
			setMsgSalvoComSucesso(msgSucess);
			getReloadByID(newEntity?.id ?? entity?.id, newEntity ?? entity)
			return {isOk: true, message: msgSucess};

		} catch (error:any) {
			console.log(error)
			setLoadingSubmit(false);
			return {isOk: false};
		}
		
		
	};
	let entityId:any = null;
	const submitFormisOK = async()=>{
		if(UrlParam.modo === 'nova' || UrlParam.modo === 'novo') 
		{
			history.push({pathname: `/rclpma/editar/${entityId}`, })
		}
	};

	const submitFormSaveData = async (acao: string, msgSucess: string)  => {
		try{
			setLoadingSubmit(true);		
			
			const projeto = defaultForm.getValues('projeto');
			const tecnicoPrincipal = defaultForm.getValues('tecnicoPrincipal');
			const getValues = Object.assign(defaultForm.getValues())

			const ensaiosLst = getValues.ensaios?.filter((e:any, index:any)=>{
				return itemRemovedEnsaios.indexOf(index) === -1;
			})
			//defaultForm.getValues('ensaios');
			let ensaiosTmp = ensaiosLst?.map((e: any)=>{
				return { 
					...e,
					id		: isNumber(e.id) === true ? e.id : null,
			}});
			const areasLst = getValues.areas?.filter((e:any, index:any)=>{
				return itemRemovedAreas.indexOf(index) === -1;
			})
			let areasTmp = areasLst?.map((e: any)=>{
				const imgF =  e.imagemFileTemp?.filter((e:any)=> e !== null && e !== undefined);
				return {
					...e,
					id		: isNumber(e.id) === true ? e.id : null,
					uid		: uuid(),
					imagemFileTemp		: imgF?.length > 0? imgF: null,
					imagemFile			: null,
					imagemFileGuid		: e.imagemFileGuid,
					imagemFileLegenda	: e.imagemFileLegenda,
					lpma_LocalId		: e.local?.value,
					local				: null,
					tecnicoResponsavelId: e.tecnicoResponsavel?.value,
					tecnicoResponsavel	: null,
					dataPlantio		: e.dataPlantio 	? (new Date(e.dataPlantio)).toUTCString(): undefined,
					dataConclusao	: e.dataConclusao 	? (new Date(e.dataConclusao)).toUTCString(): undefined,
					dataDestruicao	: e.dataDestruicao ? (new Date(e.dataDestruicao)).toUTCString() : undefined,
					areaUtilizada	: e.areaUtilizada,
				}
			});

			const eventosLst = getValues.eventos;
			let eventosTmp = eventosLst?.map((ev:any)=>{
				return {
					...ev, 
					evento					: null, 
					material				: ev.material?.value,
					eventoId				: ev.evento?.id ?? (ev.evento?.value ?? ev.eventoId),
							//ev.eventoId ?? (ev.evento?.value ?? (ev.evento?.id)),
					unidadeMedidaAutorizada	: ev.unidadeMedidaAutorizada?.value, 
					unidadeMedidaTransportada : ev.unidadeMedidaTransportada?.value, 
			}});

			const processosAnterioresTemp = getValues.processosAnteriores?.map((e:any)=>{
				return {...e, dataDecisao: e.dataDecisao ? (new Date(e.dataDecisao)).toUTCString(): undefined }
			})
			let values = {
				...getValues,
				id						: entity?.id,
				resolucaoLPMA			: entity?.tipo === 'RN35' ? 1 : 2,
				lpmaRN35_Id				: entity?.tipo === 'RN35' ? entity?.lpmaBase?.id : null,
				lpmaRN06_Id				: entity?.tipo === 'RN06' ? entity?.lpmaBase?.id : null,
				projetoId               : entity?.lpmaBase?.projetoId,
				tecnicoPrincipalId      : entity?.lpmaBase?.tecnicoPrincipalId,
				tecnicoResponsavel		: null,
				local					: null,
				ami_Id					: entity?.amiBase?.id,
				//tratosCulturais         : lpmaEntity?.tratosCulturais,
				
				titulo                  : defaultForm.getValues('titulo'),				
				
				objetivo                : getValues.objetivo ?? '',// defaultForm.getValues('objetivo'),
				dataPrevistaInicioDe    : getValues.dataPrevistaInicioDe 	? (new Date(getValues.dataPrevistaInicioDe)).toUTCString(): undefined,
				dataPrevistaInicioAte   : getValues.dataPrevistaInicioAte 	? (new Date(getValues.dataPrevistaInicioAte)).toUTCString(): undefined,
				dataPrevistaFimDe      	: getValues.dataPrevistaFimDe 	? (new Date(getValues.dataPrevistaFimDe)).toUTCString()	: undefined,
				dataPrevistaFimAte      : getValues.dataPrevistaFimAte	? (new Date(getValues.dataPrevistaFimAte)).toUTCString()	: undefined,
				//mapaFile                : defaultForm.getValues('mapaFileGuid'),
				//--------------------------------------
				ensaios					: ensaiosTmp,
				areas					: areasTmp,
				//
				construcoes: getValues.construcoes,

				datas: entityComunicadoData,
				materiaisAprovados: AtualizarMateriaisAprovados(),
				materiaisPlantados: AtualizarMateriaisPlantados(),

				//avaliacoes				: defaultForm.getValues('avaliacoes'),
				//localidades				: defaultForm.getValues('localidades'),
				dataEndosso				: defaultForm.getValues('dataEndosso') ? (new Date(getValues.dataEndosso)).toUTCString(): undefined,
				dataProposta			: defaultForm.getValues('dataProposta') ? (new Date(getValues.dataProposta)).toUTCString(): undefined,
			
				processosAnteriores		: processosAnterioresTemp,
				equipeTecnica			: getValues.equipeTecnica,

				//---------------------------------------
				observacoesRegulatorio	: getValues.observacoesRegulatorio ?? '',
				observacoes				: getValues.observacoes ?? '',
				//---------------------------------------
				status					: entity?.status,
				//---------------------------------------
				dataPrevistaColheira	: getValues.dataPrevistaColheira 	? (new Date(getValues.dataPrevistaColheira)).toUTCString(): undefined,

				dataPrevistaColheitaInicio: getValues.dataPrevistaColheitaInicio ? (new Date(getValues.dataPrevistaColheitaInicio)).toUTCString() : undefined,
				dataPrevistaColheitaTermino: getValues.dataPrevistaColheitaTermino ? (new Date(getValues.dataPrevistaColheitaTermino)).toUTCString() : undefined,

				//-----------------------------------------
				importacao				: ((getValues.importacao?.value === '1') ?? false),
				anoSafra				: defaultForm.getValues('anoSafra') ? (new Date(getValues.anoSafra)).getFullYear(): undefined,
				//---------------------------------
				ogmLiberado				: getValues.ogmLiberado,
				alteracoesGeneticas		: getValues.alteracoesGeneticas,
				monitoramento			: getValues.monitoramento,
				anexoConfidencial		: getValues.anexoConfidencial,
				efeitosInesperados		: getValues.efeitosInesperados,
				medidasBiosseguranca	: getValues.medidasBiosseguranca,
				ogmProvenienteAMI		: getValues.ogmProvenienteAMI,
				fiscalizacao			: getValues.fiscalizacao,
				resumoObjetivos			: getValues.resumoObjetivos,
				resumoResultados   		: getValues.resumoResultados,
				risco					: getValues.risco,
				fiscalizacaoConfidencial: getValues.fiscalizacaoConfidencial,
				resumoResultadosConfidencial
										: getValues.resumoResultadosConfidencial,
				justificativaAreas		: getValues.justificativaAreas,
				//------------------------
				anexoFiscalizacao: null,
				anexoFiscalizacaoGuid			: getValues.anexoFiscalizacaoGuid,
				anexoFiscalizacaoLegenda		: getValues.anexoFiscalizacaoLegenda,
				anexoFiscalizacaoTemp			: getValues.anexoFiscalizacaoTemp,
				//------------------------
				anexoTecnico: null,
				anexoTecnicoGuid		: getValues.anexoTecnicoGuid,
				anexoTecnicoLegenda		: getValues.anexoTecnicoLegenda,
				anexoTecnicoTemp		: getValues.anexoTecnicoTemp,
				//-------------------------
				eventos: eventosTmp,

				codigoLPMA: entity?.lpmaBase?.numeroProcessoEAno,
			};
			if(acao === 'correcao'){

			}

			let EnsaioDadosFormatados=[
				'largura', 'comprimento', 'parcelaLargura', 'parcelaComprimento',
				'percentualSemente', 'densidade', 'numeroRepeticoes'
			]
			const EnsaioDadosFormatadosClear = (value:any)=>{
				let isNumber = Number(value) === value && (value % 1 !== 0 || value % 1 === 0);
				if(isNumber === true){return value;}	
				if(value === null || value === undefined) { return 0;}
				
				value = value.replace("m (larg.)", '');
				value = value.replace("m (comp.)", '');
				value = value.replace("%", '');
				value = value.replace("kg/ha", '');
				value = value.replace("Nº", '');

				return parseFloat(value.trim());
			}

			const listAddFormData = (key:string, listName: string, listing: any[], formData_:any ) =>{
				if (key === listName && listing?.length > 0) {
					for(let i = 0, t = listing.length; i < t; i++){
						var itemFrom = listing[i];
						for(var ekey in itemFrom){
							if(itemFrom[ekey] !== null && itemFrom[ekey] !== undefined){
								formData_.append(`${listName}[${i}].${ekey}`, itemFrom[ekey]);
							}							
						}
					}
					return true;
				}
				return false;
			}

			const AnexoSendInfo = [
				{id: 'imagemTecnicoTemp', 		tipo: '10', legenda: 'imagemTecnicoLegenda', 		guid: 'imagemTecnicoGuid'},
				{id: 'imagemFiscalizacaoTemp',  tipo: '20', legenda: 'imagemFiscalizacaoLegenda', 	guid: 'imagemFiscalizacaoGuid'},
				{id: 'anexoTecnicoTemp', 		tipo: '30', legenda: 'anexoTecnicoLegenda',			guid: 'anexoTecnicoGuid'},
				{id: 'anexoFiscalizacaoTemp', 	tipo: '40', legenda: 'anexoFiscalizacaoLegenda',	guid: 'anexoFiscalizacaoGuid'},
				//----
			];		

			let anexosGuidCount = 0;
			let formData = new FormData();
			let keysToIgnore = ['x1'];
			for (var key in values) {				
				if (keysToIgnore.includes(key) || !values[key]) { continue; }
				const valueByKey = values[key];

				if(listAddFormData(key, 'processosAnteriores', processosAnterioresTemp,formData)){
					continue;	
				}
				if(listAddFormData(key, 'construcoes', getValues.construcoes, formData)){
					continue;	
				}
				if (listAddFormData(key, 'datas', entityComunicadoData, formData)) {
					continue;
				}
				if (listAddFormData(key, 'materiaisAprovados', AtualizarMateriaisAprovados(), formData)) {
					continue;
				}
				if (listAddFormData(key, 'materiaisPlantados', AtualizarMateriaisPlantados(), formData)) {
					continue;
				}


				if (key === 'eventos'){
					for(let i = 0, t = valueByKey.length; i < t; i++){
						const evento = valueByKey[i]
						for(var ekey in evento){
							const valByEkey = evento[ekey];
							if(	valByEkey!== null && valByEkey !== undefined){
								if(ekey === 'qtdTransportada' || ekey === 'qtdAutorizada'){
									formData.append(`eventos[${i}].${ekey}`, EnsaioDadosFormatadosClear(valByEkey));
								}
								else if(ekey === 'ami'){}
								else {
									formData.append(`eventos[${i}].${ekey}`, valByEkey);  
								}
							}
							
						}

					}
					continue;
				}
				if (key === 'equipeTecnica'){	
					const equi_tec = valueByKey.map((equi:any)=> equi.tecnico.value);				
					for(let i = 0, t = equi_tec.length; i < t; i++){
						formData.append(`equipeTecnica[${i}].tecnicoID`, equi_tec[i]);  
					}
					continue;	
				}
				if(key === 'anexoTecnicoTemp' || key === 'anexoFiscalizacaoTemp' ||
					key === 'imagemTecnicoTemp' || key === 'imagemFiscalizacaoTemp'){
					const chkInfo = AnexoSendInfo.find(a=> a.id === key);

					let files 		= valueByKey;
					let tipo 		= chkInfo?.tipo ?? '10';
					let legendas	= values[chkInfo?.legenda ?? 'anexoTecnicoLegenda'];

					// (key === 'imagemTecnicoTemp' ? '10' : 
					// 					(key === 'imagemFiscalizacaoTemp' ? '20' : 
					// 						(key === 'anexoTecnicoTemp' ? '30' : '40')));
										
					//let legendas 	= (key === 'anexoTecnicoTemp') ? values['anexoTecnicoLegenda'] : values['anexoFiscalizacaoLegenda'] ;
					if(files?.length > 0){for(let x = 0, et = files.length; x < et; x++){
						if(!(files[x] === undefined || files[x] === null)){										
							formData.append(`AnexoFile`, files[x]); 
							formData.append(`AnexoFileTipo`, tipo); 
							try{ 				formData.append(`AnexoFileLegenda`, legendas[x]);  
							}catch (erLgd) { 	formData.append(`AnexoFileLegenda`, ''); }
						}
						
					}}		
					continue;	
				}
				else if(key === 'anexoTecnicoGuid' || key === 'anexoFiscalizacaoGuid'||
				key === 'imagemTecnicoGuid' || key === 'imagemFiscalizacaoGuid'){
					let anexosGuid = values[key];

					const chkInfo = AnexoSendInfo.find(a=> a.guid === key);
					let legendas	= values[chkInfo?.legenda ?? 'anexoTecnicoLegenda'];

					//let legendas = (key === 'anexoTecnicoGuid') ? values['anexoTecnicoLegenda'] : values['anexoFiscalizacaoLegenda'] ;
					for(let y = 0, t = anexosGuid.length; y < t; y++){
						if(!(anexosGuid[y] == null || anexosGuid[y] === undefined))
						{
							let cidx = anexosGuidCount;
							formData.append(`anexoGuid[${cidx}]`, anexosGuid[y]);  
							try{			formData.append(`anexoGuidLegenda[${cidx}]`, legendas[y]);  
							}catch (erLgd) {formData.append(`anexoGuidLegenda[${cidx}]`, ''); }
							anexosGuidCount++;
						}
					}
					continue;
				}
				else if(key === 'anexoTecnicoLegenda' || key === 'anexoFiscalizacaoLegenda' || 
						key === 'imagemTecnicoLegenda' || key === 'imagemFiscalizacaoLegenda') { continue; }
				if (key === 'ensaios') {
					for(let i = 0, t = ensaiosTmp.length; i < t; i++){
						var itemEnsaio = ensaiosTmp[i];
						for(var ekey in itemEnsaio){	
							if(ekey === 'croquiFileTemp'){
								let croquiFile = itemEnsaio[ekey];
								if(croquiFile.length > 0)
								{
									for(let x = 0, et = croquiFile.length; x < et; x++){
										formData.append(`ensaios[${i}].croquiFile`, croquiFile[x]);
									}									
								}
							}
							if(ekey === 'avaliacaoAgronomica' && itemEnsaio[ekey]){
								const ava_agro = itemEnsaio[ekey] as LpmaRNEnsaioAvaliacaoAgronomica[];
								for(let av = 0, tav = ava_agro.length; av < tav; av++){
									const ava = ava_agro[av];
									formData.append(`ensaios[${i}].avaliacaoAgronomica[${av}].pE11AD1`, ava.pE11AD1);
									formData.append(`ensaios[${i}].avaliacaoAgronomica[${av}].descricao`, ava.descricao);
								}
							}
							else if(ekey === 'tratamentos' && itemEnsaio[ekey]){
								const ens_trat = itemEnsaio[ekey] as LpmaRNEnsaioTratamento[];
								for(let tt = 0, ttt = ens_trat.length; tt < ttt; tt++){
									const trat = ens_trat[tt];
									formData.append(`ensaios[${i}].tratamentos[${tt}].tipoTratamento`, trat.tipoTratamento?.value);
									formData.append(`ensaios[${i}].tratamentos[${tt}].descricao`, trat.descricao);
									formData.append(`ensaios[${i}].tratamentos[${tt}].dose`, (trat.dose ?? 0).toString());
									formData.append(`ensaios[${i}].tratamentos[${tt}].estagioAplicacao`, trat.estagioAplicacao);
								}
							}
							else if(EnsaioDadosFormatados.includes(ekey)){
								formData.append(`ensaios[${i}].${ekey}`, EnsaioDadosFormatadosClear(itemEnsaio[ekey]));
							}
							else if(itemEnsaio[ekey])
							{
								formData.append(`ensaios[${i}].${ekey}`, itemEnsaio[ekey]);
							}							
						}
					}				
					continue;	
				}
				if (key === 'areas') {
					for(let i = 0, t = areasTmp.length; i < t; i++){
						var imagensGuidCount = 0;
						var itemArea = areasTmp[i];
						for(var akey in itemArea){
							const valByEkey = itemArea[akey];
							if(	valByEkey!== null && valByEkey !== undefined){							
								if(akey === 'imagemFileTemp'){
									let files 		= itemArea[akey];
									let legendas 	= itemArea['imagemFileLegenda'];
									if(files?.length > 0){for(let x = 0, et = files.length; x < et; x++){
										if(!(files[x] === undefined || files[x] === null)){										
											formData.append(`areas[${i}].imagemFile`, files[x]); 
											try{ 				formData.append(`areas[${i}].imagemFileLegenda`, legendas[x]);  
											}catch (erLgd) { 	formData.append(`areas[${i}].imagemFileLegenda`, ''); }
										}
										
									}}		
								}
								else if(akey === 'imagemFileLegenda'){/** */}
								else if(akey === 'imagemFileGuid'){
									let legendakey = 'imagemFileLegenda';
									let figurasGuid = itemArea[akey];
									let legendas = itemArea[legendakey];
									for(let y = 0, t = figurasGuid.length; y < t; y++){
										if(!(figurasGuid[y] == null || figurasGuid[y] === undefined))
										{
											let cidx = imagensGuidCount;
											formData.append(`areas[${i}].imagensGuid[${cidx}]`, figurasGuid[y]);  
											try{			formData.append(`areas[${i}].imagensGuidLegenda[${cidx}]`, legendas[y]);  
											}catch (erLgd) {formData.append(`areas[${i}].imagensGuidLegenda[${cidx}]`, ''); }
											imagensGuidCount++;
										}
									}
								}
								else if(akey === 'mapaFileTemp'){
									let mapaFile = itemArea[akey];
									if(mapaFile.length > 0)
									{
										for(let x = 0, et = mapaFile.length; x < et; x++){
											formData.append(`areas[${i}].mapaFile`, mapaFile[x]);
										}									
									}
								}
								else if(akey === 'areaEnsaio' && itemArea[akey]){
									const areaEnsaio = itemArea[akey];
									if(areaEnsaio.length > 0){
										for(let x = 0, et = areaEnsaio.length; x < et; x++){
											const areaUid = areaEnsaio[x];										
											formData.append(`areas[${i}].ensaioUid[${x}]`, areaUid.value);
										}
									}								
								}
								else if(itemArea[akey])
								{
									formData.append(`areas[${i}].${akey}`, itemArea[akey]);
								}	
							}
						}
					}
					continue;
				}

				formData.append(key, valueByKey);
			}

			const result = await RCLpmaServiceApi.getRCLPMAAction(acao, formData);
			entityId =   result.id;
			setLoadingSubmit(false);			
			setMsgSalvoComSucesso(msgSucess);
			getReloadByID(entityId, result)
			//setEntity(result);
			return {isOk: true, message: msgSucess};
			
		} catch (error:any) {
			console.log('Ação:', acao, 'error:', error);
			setLoadingSubmit(false);
			return {isOk: false};
		}	
	};	

	const getLoadByDto = async (id_: any, dto: any, rnTipo: string | 'rn35' | 'rn06', initData_: any = null, dadosComunicados: any = null) => {

		const tpTrat_ 	= initData_?.itemsTipoTratamento ?? initData?.itemsTipoTratamento;
		const tecnicos_ = initData_?.equipeTecnica ?? initData?.equipeTecnica;
		const locais_ = initData_?.locais ?? initData?.locais;
		const construcoesGene06 = initData_?.potenciaisConstrucoes06 ?? initData?.potenciaisConstrucoes06;
		const construcoesGene35 = initData_?.potenciaisConstrucoes35 ?? initData?.potenciaisConstrucoes35;

		const getEnumRef = (tLista: any[], Tid: any, labelTrat:any = null)=>{
			if(Tid === null){ return null; }
			let elm = tLista?.find(e=> e.id === Tid);
			if(elm === null){ return null; }
			return {...elm, value: Tid, label: (!labelTrat ? elm?.nome : labelTrat(elm)) };
		}

		const getByLocalRef = (locais_: any[], e: any)=>{
			if(e === null){ return null; }
			let elmLocal = locais_?.find(elm=> elm.id === e.lpma_LocalId);
			if(elmLocal === null){ return null; }
			return {...elmLocal, value: e.lpma_LocalId, label: elmLocal?.nomeDaFazenda};
		}

		const genesDto = (
			rnTipo == 'rn06' ?
				dto.lpmaBase != null ? dto.lpmaBase?.genesInteresse : dto.fK_LpmaRN06?.genesInteresse
				:
				dto.lpmaBase != null ? dto.lpmaBase?.genesInteresse : dto.fK_LpmaRN35?.genesInteresse)

		const oficios = dto.lpmaBase?.oficios;

		const rcLpma = {
			...dto,
			id: id_,
			status: STATUS.EmPreenchimento,
			projeto: `${dto.projeto?.nome} (${dto.projeto?.cultura})`,
			medidasBiosseguranca: dto.medidasBiosseguranca,
			tecnicoPrincipal: dto.tecnicoPrincipal?.nome,
			lpmaBase: dto.lpmaBase,
			amiBase: dto.amiBase,
			statusAtualLpma: dto?.lpmaBase?.status ?? dto?.status,
			tipo: (rnTipo === 'rn35' ? 'RN35' : 'RN06'),
			dataPrevistaInicioDe: dto.dataPrevistaInicioDe ? moment(dto.dataPrevistaInicioDe).toDate() : null,
			dataPrevistaInicioAte: dto.dataPrevistaInicioAte ? moment(dto.dataPrevistaInicioAte).toDate() : null,
			dataPrevistaFimDe: dto.dataPrevistaFimDe ? moment(dto.dataPrevistaFimDe).toDate() : null,
			dataPrevistaFimAte: dto.dataPrevistaFimAte ? moment(dto.dataPrevistaFimAte).toDate() : null,

			construcoes: dto.construcoes?.map((e: any) => {
				return { ...e, processoCTNBio: e.processoCTNBio ?? '' }
			}),

			genesinteresse:
				rnTipo === 'rn35' ?
					genesDto.map((e: any) => {
						var construcaoGene35 = construcoesGene35?.find((cons: any) => cons.id === e.construcaoId);
						return {
							...e,
							nomeConstrucao: construcaoGene35.nomeContrucao,
							nomeGeneInteresse: e.nomeGeneInteresse,
							organismoDoadorGeneInteresse: e.organismoDoadorGeneInteresse,
							funcaoGeneInteresse: e.funcaoGeneInteresse,
							funcaoResumida: e.funcaoGeneInteresse.substring(0, 50) + "...",
						}
					})
					:
					genesDto.map((e: any) => {
						var construcaoGene06 = construcoesGene06?.find((cons: any) => cons.id === e.construcaoId);
						return {
							...e,
							nomeConstrucao: construcaoGene06.nomeContrucao,
							nomeGeneInteresse: e.nomeGeneInteresse,
							organismoDoadorGeneInteresse: e.organismoDoadorGeneInteresse,
							funcaoGeneInteresse: e.funcaoGeneInteresse,
							funcaoResumida: e.funcaoGeneInteresse.substring(0, 50) + "...",
						}
					}),

			ensaios	: 	dto.ensaios?.map((e:any)=> {
									return {
										...e, 
										uid: uuid(), 
										tratamentos: e.tratamentos?.map((t:any)=>{ 
											return {
												...t,
												uid: uuid(), 
												tipoTratamento: getEnumRef(tpTrat_, t.tipoTratamento),
											}}),
				}
			}),
			
			areas: dto.areas?.map((e: any) => {
				var Local = e.lpma_Local;

				let dataPlantioTermino = null;
				let dataDestruicaoTermino = null;
				let dataConclusaoTermino = null;

				if (e.area_Ensaio !== undefined && dadosComunicados?.datas !== undefined)
				{
					for (let i = 0; i < e.area_Ensaio.length; i++) {
						const areaEnsaioSelecionada = e.area_Ensaio[i];
						const datasComunicado = dadosComunicados.datas.find((x: any) => x.area_Ensaio_ID === areaEnsaioSelecionada.id);

						if (datasComunicado?.dataTerminoPlantio > dataPlantioTermino || dataPlantioTermino === null)
							dataPlantioTermino = datasComunicado?.dataTerminoPlantio;

						if (datasComunicado?.dataTerminoColheitaDestruicao > dataDestruicaoTermino || dataDestruicaoTermino === null)
							dataDestruicaoTermino = datasComunicado?.dataTerminoColheitaDestruicao;

						if (datasComunicado?.dataTerminoMonitoramento > dataConclusaoTermino || dataConclusaoTermino === null)
							dataConclusaoTermino = datasComunicado?.dataTerminoMonitoramento;

						//var materiaisAprovados = dadosComunicados.materiaisAprovados.find((x: any) => x.area_Ensaio_ID === areaEnsaioSelecionada.id);
						//var materiaisPlantados = dadosComunicados.materiaisPlantados.find((x: any) => x.area_Ensaio_ID === areaEnsaioSelecionada.id);
					};
				}

				if (e.dataPlantio !== null)
					dataPlantioTermino = e.dataPlantio;

				if (e.dataDestruicao !== null)
					dataDestruicaoTermino = e.dataDestruicao;

				if (e.dataConclusao !== null)
					dataConclusaoTermino = e.dataConclusao;

				return {
					...e,  
					uid: uuid(), 
					//figuras: allFiguras,
					tecnicoResponsavel: getEnumRef(tecnicos_, e?.tecnicoResponsavelId),  
					local: getByLocalRef(locais_, e),
					imagemFile			 : !e.figuras ? null : e.figuras.map((e:any)=> {return {name: e.fileName, guid: e.fileGuid, desc: e.legenda}}),
					imagemFileName		: !e.figuras ? null : e.figuras.map((e:any)=> e.fileName),
					imagemFileGuid		: !e.figuras ? null : e.figuras.map((e:any)=> e.fileGuid),
					imagemFileLegenda	: !e.figuras ? null : e.figuras.map((e:any)=> e.legenda),
					dataConclusao: dataConclusaoTermino ? moment(dataConclusaoTermino).toDate() : null,
					dataDestruicao: dataDestruicaoTermino ? moment(dataDestruicaoTermino).toDate() : null,
					dataPlantio: dataPlantioTermino ? moment(dataPlantioTermino).toDate() : null,
					areaUtilizada		: e.areaUtilizada ? e.areaUtilizada	: false,
					//dataPlantio: getDataPlantio(areas_, e),

			}}),

			dataEndosso				: dto.dataEndosso ? moment(dto.dataEndosso).toDate() : null,
			dataProposta			: dto.dataProposta ? moment(dto.dataProposta).toDate() : null,

			processosAnteriores		: dto.processosAnteriores?.map((e:any)=>{
									return {
									...e, 
									uid: uuid(), 
									dataDecisao: e.dataDecisao ? moment(e.dataDecisao).toDate() : null }
									}),
			equipeTecnica		: dto.equipeTecnica?.map((e:any)=>{
									return {
									...e,
									tecnico: getEnumRef(tecnicos_, e?.tecnico_Id),  
									// tecnico: getByTecnicoRef(tecnicos_, e?.tecnico_Id), 
									}}),

			dataPrevistaColheira: dto.dataPrevistaColheira ? moment(dto.dataPrevistaColheira).toDate() : null,

			dataPrevistaColheitaInicio: dto.dataPrevistaColheitaInicio ? moment(dto.dataPrevistaColheitaInicio).toDate() : null,
			dataPrevistaColheitaTermino: dto.dataPrevistaColheitaTermino ? moment(dto.dataPrevistaColheitaTermino).toDate() : null,

			importacao			: {value: (dto.importacao === true ? '1' : '0'), label: (dto.importacao === true ? 'Sim' : 'Não')},
			anoSafra			: dto.anoSafra ? (new Date()).setFullYear(dto.anoSafra) : null,
		} as dto.RCLpmaDto;

		let AreaEnsaioIDInicial = 0;

		rcLpma.areas?.forEach((areas:any)=>{
			areas.area_Ensaio.forEach((dd: any) => {
				if (AreaEnsaioIDInicial === 0)
					AreaEnsaioIDInicial = dd.id;
			})
			areas.areaEnsaio = areas.area_Ensaio.map((e:any, i: number)=>{
				const ensaioDef = rcLpma.ensaios?.find((ens:any)=> ens.id === e.ensaioId);
				return {
					...e,
					...ensaioDef,	
					ensaio: ensaioDef,
					uid: uuid(), 			
					value: ensaioDef.uid,
					label: ensaioDef.descricao,
				}
			})			
		})

		if (id_ !== null) {
			const materiaisAprovadosComunicados = await RCLpmaServiceApi.getRCLPMAMateriaisAprovadosComunicadosById(id_);
			const materiaisPlantadosComunicados = await RCLpmaServiceApi.getRCLPMAMateriaisPlantadosComunicadosById(id_);

			setEntityComunicadoData(dadosComunicados.datas);
			setEntityComunicadoMaterialAprovado(materiaisAprovadosComunicados);
			setEntityComunicadoMaterialPlantado(materiaisPlantadosComunicados);

			const materiaisAprovadosArea = materiaisAprovadosComunicados.filter(x => x.area_Ensaio_ID === AreaEnsaioIDInicial);
			const materiaisPlantadosArea = materiaisPlantadosComunicados.filter(x => x.area_Ensaio_ID === AreaEnsaioIDInicial);

			setEntityComunicadoMaterialAprovadoGrid(materiaisAprovadosArea);
			setEntityComunicadoMaterialPlantadoGrid(materiaisPlantadosArea);
		}
		else {
			setEntityComunicadoData(dadosComunicados.datas);
			setEntityComunicadoMaterialAprovado(dadosComunicados.materiaisAprovados);
			setEntityComunicadoMaterialPlantado(dadosComunicados.materiaisPlantados);

			const materiaisAprovadosArea = dadosComunicados.materiaisAprovados.filter((x: any) => x.area_Ensaio_ID === AreaEnsaioIDInicial);
			const materiaisPlantadosArea = dadosComunicados.materiaisPlantados.filter((x: any) => x.area_Ensaio_ID === AreaEnsaioIDInicial);

			setEntityComunicadoMaterialAprovadoGrid(materiaisAprovadosArea);
			setEntityComunicadoMaterialPlantadoGrid(materiaisPlantadosArea);
		}

		return rcLpma;
	}

	const getLoadById = async (id: any , initData:any, modoEditar: boolean = false) =>{

		setTitleForm("Carregando Relatório Conclusivo Selecionado...")		
		setLoadView(true);
		const dload = await RCLpmaServiceApi.getRCLPMAById(id);	

		let baseApi: string = "";

		if (dload.resolucaoLPMA === 1)
			baseApi = "/api/v1/lpmacomunicadosrn35/" + dload!.lpmaRN35_Id;
		else
			baseApi = "/api/v1/lpmacomunicadosrn06/" + dload!.lpmaRN06_Id;

		const dadosComunicados = await api.get<OficioCIBIO>(baseApi);

		const dados = await getLoadByDto(id, dload, dload.resolucaoLPMA === 1 ? 'rn35' : 'rn06', initData, dadosComunicados.data);

		const parsenData = {
			//...dload,
			...dados,
			anexoFiscalizacaoTemp	: null,
			anexoFiscalizacao		: !dados.anexoFiscalizacao ? null : dados.anexoFiscalizacao.map((e:any)=> {return {name: e.fileName, guid: e.fileGuid, desc: e.legenda}}),
			anexoFiscalizacaoName	: !dados.anexoFiscalizacao ? null : dados.anexoFiscalizacao.map((e:any)=> e.fileName),
			anexoFiscalizacaoGuid	: !dados.anexoFiscalizacao ? null : dados.anexoFiscalizacao.map((e:any)=> e.fileGuid),
			anexoFiscalizacaoLegenda: !dados.anexoFiscalizacao ? null : dados.anexoFiscalizacao.map((e:any)=> e.legenda),
			
			imagemFiscalizacaoTemp	: null,
			imagemFiscalizacao		: !dados.imagemFiscalizacao ? null : dados.imagemFiscalizacao.map((e:any)=> {return {name: e.fileName, guid: e.fileGuid, desc: e.legenda}}),
			imagemFiscalizacaoName	: !dados.imagemFiscalizacao ? null : dados.imagemFiscalizacao.map((e:any)=> e.fileName),
			imagemFiscalizacaoGuid	: !dados.imagemFiscalizacao ? null : dados.imagemFiscalizacao.map((e:any)=> e.fileGuid),
			imagemFiscalizacaoLegenda: !dados.imagemFiscalizacao ? null : dados.imagemFiscalizacao.map((e:any)=> e.legenda),
		
			anexoTecnicoTemp		: null,
			anexoTecnico	  		: !dados.anexoTecnico ? null : dados.anexoTecnico.map((e:any)=> {return {name: e.fileName, guid: e.fileGuid, desc: e.legenda}}),
			anexoTecnicoName		: !dados.anexoTecnico ? null : dados.anexoTecnico.map((e:any)=> e.fileName),
			anexoTecnicoGuid		: !dados.anexoTecnico ? null : dados.anexoTecnico.map((e:any)=> e.fileGuid),
			anexoTecnicoLegenda		: !dados.anexoTecnico ? null : dados.anexoTecnico.map((e:any)=> e.legenda),
			
			imagemTecnicoTemp		: null,
			imagemTecnico	  		: !dados.imagemTecnico ? null : dados.imagemTecnico.map((e:any)=> {return {name: e.fileName, guid: e.fileGuid, desc: e.legenda}}),
			imagemTecnicoName		: !dados.imagemTecnico ? null : dados.imagemTecnico.map((e:any)=> e.fileName),
			imagemTecnicoGuid		: !dados.imagemTecnico ? null : dados.imagemTecnico.map((e:any)=> e.fileGuid),
			imagemTecnicoLegenda	: !dados.imagemTecnico ? null : dados.imagemTecnico.map((e:any)=> e.legenda),
			

			status	: 	dload.status,
			eventos : 	dados.eventos?.map(e=>{ 
				var evnt = initData?.events?.find((ex:any)=> ex.key === e.eventoId || ex.id === e.eventoId);
				var und1 = initData?.unidadeMedida?.find((ex:any)=> ex.key === e.unidadeMedidaAutorizada);
				var und2 = initData?.unidadeMedida?.find((ex:any)=> ex.key === e.unidadeMedidaTransportada);
				var matt = initData?.material?.find((ex:any)=> ex.key === e.material);				

				return{
				...e,
				uid							: uuid(),
				evento						: {...evnt,		value: e.eventoId, label: evnt?.nome },
				material					: {...matt, 	value: e.material, label: matt?.value },
				unidadeMedidaAutorizada		: {...und1, 	value: e.unidadeMedidaAutorizada, label: und1?.value },
				unidadeMedidaTransportada	: {...und2,		value: e.unidadeMedidaTransportada, label: und2?.value },
				amiNumAutorizacao			: e?.ami?.codigo ?? null,
				amiObjetivo					: e?.ami?.objetivoUtilizacao ?? null,
			}}),
		}	

		setAmiBase(parsenData.amiBase);
		setAmiEventos(parsenData.eventos)

		let estagio_ = parsenData.status ?? STATUS.EmAnalisePeloRegulatorio;
		//setEstagio(estagio_);
		//setAmiBase(parsenData.amiBase)
		setEstagio(estagio_);	
		setRequiredTec(estagio_ === STATUS.EmAnaliseTecnico && RCLpmaRoles.isTecnico())		
		
		defaultForm.reset(parsenData);			
		setEntity(parsenData);
		setItemRemovedAreas([])
		setItemRemovedEnsaios([])
		//setEnsaios(parsenData.ensaios ?? []);

		setLoadView(false);

		if(UrlParam.modo !== 'editar' && !modoEditar){
			//!visualizar
			setEditarAll(false);
			setRequiredTec(false);			
			setTitleForm(STRINGS_RCLPMA.VIEW_RCLPMA)
		}
		else {
			const lpmaAtiva = !(parsenData?.statusAtualLpma === STATUS.Cancelado)
			setTitleForm(STRINGS_RCLPMA.EDIT_RCLPMA);				
			const edt1 = (((estagio_ === STATUS.EmPreenchimento || estagio_ === STATUS.EmAnalisePeloRegulatorio) && RCLpmaRoles.isRegulatorio()) || RCLpmaRoles.isAdmin());
			const edt2 = ((estagio_ === STATUS.EmAnaliseTecnico && RCLpmaRoles.isTecnico()) || RCLpmaRoles.isAdmin());
			setEditarAll((edt1 && lpmaAtiva));
			setRequiredTec((edt2 && lpmaAtiva));
		}

	}

	const AMISelToList = async (itemsel:any)=>{
		const amiBase_ = await RCLpmaServiceApi.getAMIById(itemsel.id);
		const amiEventosSel = amiBase_.eventos?.map(e=>{ 
			var evnt = autDataDefault?.events?.find((ex:any)=> ex.key === e.eventoId || ex.id === e.eventoId);
			var und1 = autDataDefault?.unidadeMedida?.find((ex:any)=> ex.key === e.unidadeMedidaAutorizada);
			var und2 = autDataDefault?.unidadeMedida?.find((ex:any)=> ex.key === e.unidadeMedidaTransportada);
			var matt = autDataDefault?.material?.find((ex:any)=> ex.key === e.material);				

			return{
			...e,
			amiId						: amiBase_?.id,
			ami							: amiBase_,
			uid							: uuid(),
			evento						: {...evnt,		value: e.eventoId, label: evnt?.nome },
			material					: {...matt, 	value: e.material, label: matt?.value },
			unidadeMedidaAutorizada		: {...und1, 	value: e.unidadeMedidaAutorizada, label: und1?.value },
			unidadeMedidaTransportada	: {...und2,		value: e.unidadeMedidaTransportada, label: und2?.value },
			amiNumAutorizacao			: amiBase_?.codigo ?? null,
			amiObjetivo					: amiBase_?.objetivoUtilizacao ?? null,
		}});

		return {ami: amiEventosSel, eventos: amiEventosSel}
	}

	const EnsaiosList = ()=>{
		const list = defaultForm.watch('ensaios') ?? defaultForm.getValues('ensaios') 
		if(!list) return [];
		list.forEach((e:any, index: number)=> { if(!e.uid) {
			e.uid = e.uid ?? uuid()
			defaultForm.register(`ensaios.${index}.uid`)
			defaultForm.setValue(`ensaios.${index}.uid`, e.uid );
		}});
		return list;		
	}
	const ButtonSeachView = (acao: 'AMI' | 'LPMA' | null)=>{		
		return <EditButtons.SearchButton disable={!editarAll} tooltip={'Pesquisar'}  
		onClick={()=>{ setShowSearch(acao) }} /> 
	}
	const isNoEmpty = (str:any) => {
		return !(!str || str.length === 0 );
	}
	const ButtonSearchLPMA = ()=>{
		return <SearchBtnDialog
		onClose={()=>  setShowSearch(null)}
		msgText='Selecionado LPMA, Aguarde...'
		showMe={(showSearch === 'LPMA')}
		name = {`searchLPMA-1`}
		form = {searchLPMAForm}
		title="Pesquisar LPMA Aprovadas"
		filters={(dftform:any)=>{return <>			
			<TextField  layoutColumns={4} name="numeroAutorizacao" label="Código de Controle" form={dftform}  required={false}  isClearable={true} />
			<TextField name="solicitante" label="Solicitante/Requisitante" form={dftform} 	isClearable	layoutColumns={3}  />
			<TextField  layoutColumns={4} name="titulo" label="Título" form={dftform}  required={false}  isClearable={true} />
			
			<DropDown name="tecnicoPrincipal" label="Tecnico Responsável" form={dftform}  layoutColumns={3} 
			options={initData?.equipeTecnica?.map((i:any) => Object.assign({ value: i.id, label: i.nome }))}	isClearable={true} />

			<LabelArea layoutColumns={3}>LPMA Baseada em qual resolução normativa</LabelArea>
			<RadioBox form={dftform} name="lpmarn" layoutColumns={1}
				label="RN 35" value="rn35" enabled={true} defaultChecked={false}
			/> 
			<RadioBox form={dftform} name="lpmarn" layoutColumns={1}
				label="RN 06" value="rn06" enabled={true} defaultChecked={false}
			/> 
			<RadioBox form={dftform} name="lpmarn" layoutColumns={1}
				label="Todas" value="all" enabled={true} defaultChecked={true}
			/> 
		</>}}
		searching={async (buscando:any)=>{
			// let filtro = {
			// 	tipo					: defaultForm.getValues('tipo')?.value,
			// 	localId               	: defaultForm.getValues('local')?.value,
			// 	//tecnicoPrincipalId      : defaultForm.getValues('tecnicoPrincipal')?.value,
			// 	status					: defaultForm.getValues('status')?.value,
			// 	solicitante				: defaultForm.getValues('solicitante'),
			// }; //as CqbDto;
			buscando = {...buscando,
				tecnicoPrincipalId	: buscando?.tecnicoPrincipal?.value,
				status				: STATUS.Aprovado,
			};
			const fRN06 = (buscando.lpmarn === 'rn06' || buscando.lpmarn === 'all') ? (await LPMAServiceApi.getListRN06(buscando)).map((e:any)=>{return {...e, lpmarn:'rn06' }}) : [];
			const fRN35 = (buscando.lpmarn === 'rn35' || buscando.lpmarn === 'all') ? (await LPMAServiceApi.getListRN35(buscando)).map((e:any)=>{return {...e, lpmarn:'rn35' }}) : [];
			
			let lpmaByApi=[
				...fRN06, ...fRN35
				// ...(await LPMAServiceApi.getListRN35(buscando)).map((e:any)=>{return {...e, lpmarn:'rn35' }}),
				// ...(await LPMAServiceApi.getListRN06(buscando)).map((e:any)=>{return {...e, lpmarn:'rn06' }}),
			]			
			return lpmaByApi;
		}}
		selectResult={async (itemsel:any, formDataValues:any)=>{
			let lpmaByApi:any = null;
			//formDataValues.lpmarn
			switch(itemsel.lpmarn){
				case 'rn35': lpmaByApi = await LPMAServiceApi.getRN35ById(itemsel.id); break;
				case 'rn06': lpmaByApi = await LPMAServiceApi.getRN06ById(itemsel.id); break;
			}

			const cfgLpma = initData?.configLpma?.find(e=> e.parametro === 'TEXTO_PERIODO_MONITORAMENTO');
			let monitor = null;
			if(cfgLpma){
				monitor = cfgLpma.conteudo.replace('{{CULTURA_NOME_CIENTIFICO}}', (lpmaByApi?.projeto?.nomeCientifico ?? '[Nome Científico não informado]') )
			}

			const resumo = (lpmaByApi?.ensaios?.map((ens:any)=>{
				return (isNoEmpty(ens.objetivo) ? `<p><strong>Objetivo:</strong>${ens.objetivo.trim()}</p>` : '') +
				(isNoEmpty(ens.material) 					? `<p><strong>Material:</strong> ${ens.material.trim()}</p>` : '') +
				(isNoEmpty(ens.delineamentoExperimental) 	? `<p><strong>Delineamento Experimental:</strong> ${ens.delineamentoExperimental.trim()}</p>` : '') +
				(isNoEmpty(ens.tratosCulturais) 			? `<p><strong>Tratamentos Culturais:</strong> ${ens.tratosCulturais.trim()}</p>` : '') +
				(isNoEmpty(ens.avaliacaoAgronomicaTexto) 	? `<p><strong>Avaliações dos parâmetros Agronômicos:</strong> ${ens.avaliacaoAgronomicaTexto.trim()}</p>` : '')										
				
				}) ?? []).join('<br />');
			
			const rcLpmaRef = {
				...lpmaByApi,
				id: null,
				amiBase	: null,
				lpmaBase: lpmaByApi,
				ogmLiberado			: lpmaByApi?.projeto?.ogm ?? lpmaByApi?.ogmLiberado,
				monitoramento		: monitor ?? lpmaByApi?.monitoramento,
				alteracoesGeneticas	: lpmaByApi?.objetivo,	
				medidasBiosseguranca: (itemsel.lpmarn === 'rn35' ? 
									lpmaByApi?.projeto?.medidasBiossegurancaRN35 :
									lpmaByApi?.projeto?.medidasBiossegurancaRN06),
				resumoObjetivos		: resumo,
				observacoesRegulatorio: '',
				observacoes: '',
				justificativaAreas	: lpmaByApi?.justificativaAreas,
				codigoLPMA			: lpmaByApi?.codigoLPMA,
			}

			let baseApi: string = "";

			if (itemsel.lpmarn === 'rn35')
				baseApi = "/api/v1/lpmacomunicadosrn35/" + itemsel.id;
			else
				baseApi = "/api/v1/lpmacomunicadosrn06/" + itemsel.id;

			const dadosComunicados = await api.get<OficioCIBIO>(baseApi);

			const rcLpma = await getLoadByDto(null, rcLpmaRef , itemsel.lpmarn, null, dadosComunicados.data);
			defaultForm.reset(rcLpma);			
			setEntity(rcLpma);
			setItemRemovedAreas([])
			setItemRemovedEnsaios([])
			
			//setEnsaios(rcLpma.ensaios ?? []);
			return true;
		}}
		actionsMaxWidth={75}
		>
			<ListCol header='Nº Controle' field='numeroProcessoEAno' maxWidth={100} 	headerClass="myfunc-default-header" sort="asc" />	
			
			<ListCol header='LPMA/Projeto- Titulo' minWidth={400} valueFormatter={({ data }: any) => {
				const rnN = (data.lpmarn === 'rn35' ? 'LPMA RN 35' : 'LPMA RN 06');
				const prj = (data?.projeto ? ` (${data?.projeto?.nome} - ${data?.projeto?.cultura}) ` : ' ');
				return `${rnN}${prj}${data.titulo}`			
			}} 
			field='Titulo' wrapText	headerClass="myfunc-default-header" cellStyle={{ justifyContent: 'left' }}  />	

			{/* <ListCol header='LPMA' maxWidth={100} valueFormatter={({ data }: any) => data.lpmarn === 'rn35' ? 'LPMA RN 35' : 'LPMA RN 06' } field='lpmarn' 	headerClass="myfunc-default-header" />	
			<ListCol header='Titulo' headerClass="myfunc-default-header"
			field='titulo' cellStyle={{ justifyContent: 'left' }} />
			<ListCol header='Projeto' field='projeto' maxWidth={150}
			valueFormatter={({ data }: any) => data?.projeto ? `${data?.projeto?.nome} - ${data?.projeto?.cultura}` : ''}
			headerClass="myfunc-default-header" cellStyle={{ justifyContent: 'left' }} />		 */}

			<ListCol maxWidth={200}  header='Solicitante/ Requisitante' field='solicitante' headerClass="myfunc-default-header" />
			
			<ListCol header='Tecnico Responsável' headerClass="myfunc-default-header"
			field='tecnicoPrincipal' valueFormatter={({ data }: any) => data?.tecnicoPrincipal?.nome ?? ''}
			cellStyle={{ justifyContent: 'left' }} />	

			<ListCol header='Data do Endosso' field='dataEndosso' headerClass="myfunc-default-header"
				maxWidth={130} valueFormatter={({ data }: any) => data.dataEndosso ? _generateDate(data.dataEndosso, 'Pt') : ''}/>	

		</SearchBtnDialog>;
	}

	const ButtonSearchAMI = ()=>{
		return <SearchBtnDialog
		onClose={()=>  setShowSearch(null)}
		msgText='Selecionado AMI, Aguarde...'
		showMe={(showSearch === 'AMI')}
		name="searchAMI"
		form={searchAMIForm}
		multiSelect={true}
		title="Pesquisar AMI Aprovadas"
		filters={(dftform:any)=>{return <>	
			<TextField name="origem" label="Origem" form={dftform} defaultValue={entity?.lpmaBase?.numeroProcessoEAno}	isClearable	layoutColumns={2}  />
		
			<TextField name="solicitante" label="Solicitante/Requisitante" form={dftform} 	isClearable	layoutColumns={3}  />
						
			<DropDown name="cultura" label="Cultura/Organismo" form={dftform}  layoutColumns={2} 
			options={autDataDefault?.cultures?.map((c:any) => Object.assign({ value: c.id, label: c.nome }))}isClearable={true}/>
			
			
			<DateField name="dataInicial" label="Data Inicial" layoutColumns={2} form={dftform} enabled={editarAll || requiredTec} ></DateField> 
			<DateField name="dataFinal" label="Data Final" minDate={dftform.watch().dataInicial} layoutColumns={2} form={dftform} enabled={editarAll || requiredTec} ></DateField> 
			
			<TextField name="numeroAutorizacaoCibio" label="Nº Autorização CIBio" form={dftform} 	isClearable	layoutColumns={2}  />

			<DropDown name="localEnvio" label="Local de envio/partida" form={dftform}  layoutColumns={4} 
			options={initData?.locais?.map(i => Object.assign({ value: i.id, label: `${i.nomeDaFazenda} - ${i.municipio} ${i.uF}` }))}isClearable={true}/>
			
			<DropDown name="localDestino" label="Local de destino" form={dftform}  layoutColumns={4} 
			options={initData?.locais?.map(i => Object.assign({ value: i.id, label: `${i.nomeDaFazenda} - ${i.municipio} ${i.uF}` }))}isClearable={true}/>
			
		</>}}
		searching={async (buscando:any)=>{
					
			const buscando_mount ={
				...buscando,
				numeroAutorizacaoCibio :buscando?.numeroAutorizacaoCibio ?? '',
				origem		: buscando?.origem ?? '',
				cultura		: buscando?.cultura?.value ?? '',
				localDestino: buscando?.localDestino?.value ?? '',
				localEnvio	: buscando?.localEnvio?.value ?? '',
				dataInicial	: buscando?.dataInicial ? _generateDate(new Date(buscando?.dataInicial), 'En') : '',
				dataFinal	: buscando?.dataFinal ? _generateDate(new Date(buscando?.dataFinal), 'En') : ''
			};
			const amiByApi = RCLpmaServiceApi.getAMI(buscando_mount);
			return amiByApi;
		}}
		selectMultiResult={async (amisSel: any[])=>{
			setEventListAMI(false);
			var eventsResult = [] as any[];
			for(let i = 0; i < amisSel.length; i++){
				const itemsel = amisSel[i];
				const amiGet = await AMISelToList(itemsel);
				eventsResult.pushArray(amiGet.eventos)
			}
			const listEventos = Object.assign(defaultForm.getValues())?.eventos ?? [];
			eventsResult.pushArray(listEventos)
			defaultForm.setValue('eventos', []);
			
			setTimeout(() => { 
				setAmiEventos([]);
				setAmiEventos(eventsResult);
				setEventListAMI(true);
			}, 2000)		
			return true;
		}}
		selectResult={async (itemsel:any, formDataValues:any)=>{

			/*TODO: Remover
			const amiBase_ = await RCLpmaServiceApi.getAMIById(itemsel.id);
			const amiEventosSel = amiBase_.eventos?.map(e=>{ 
				var evnt = autDataDefault?.events?.find((ex:any)=> ex.key === e.eventoId || ex.id === e.eventoId);
				var und1 = autDataDefault?.unidadeMedida?.find((ex:any)=> ex.key === e.unidadeMedidaAutorizada);
				var und2 = autDataDefault?.unidadeMedida?.find((ex:any)=> ex.key === e.unidadeMedidaTransportada);
				var matt = autDataDefault?.material?.find((ex:any)=> ex.key === e.material);				

				return{
				...e,
				amiId						: amiBase_?.id,
				ami							: amiBase_,
				uid							: uuid(),
				evento						: {...evnt,		value: e.eventoId, label: evnt?.nome },
				material					: {...matt, 	value: e.material, label: matt?.value },
				unidadeMedidaAutorizada		: {...und1, 	value: e.unidadeMedidaAutorizada, label: und1?.value },
				unidadeMedidaTransportada	: {...und2,		value: e.unidadeMedidaTransportada, label: und2?.value }
			}});	*/

			const amiGet = await AMISelToList(itemsel);
			const amiEventosSel = amiGet.eventos;

			setEventListAMI(false);
			
			const listEventos = Object.assign(defaultForm.getValues())?.eventos ?? [];

			var eventsResult = [] as any[];
			eventsResult
			.pushArray(amiEventosSel)
			.pushArray(listEventos);
			
			defaultForm.setValue('eventos', []);
			
			setTimeout(() => { 
				setAmiEventos([]);
				setAmiEventos(eventsResult);
				setEventListAMI(true);
			}, 2000)		

			
			//setAmiBase(amiBase_)

			return true;
		}}>
			<ListCol header='Código' field='id' maxWidth={75} 	headerClass="myfunc-default-header" sort="asc" />
			
			<ListCol header='Origem' field='origem' headerClass="myfunc-default-header" />	

			<ListCol header='Solicitante/ Requisitante' field='requisitante' headerClass="myfunc-default-header" />
			
			<ListCol header='Local do envio/ partida' headerClass="myfunc-default-header"
			field='localPartidaNome' cellStyle={{ justifyContent: 'left' }} />	
			
			<ListCol header='Local de destino' headerClass="myfunc-default-header"
			field='localDestinoNome' cellStyle={{ justifyContent: 'left' }} />	

			<ListCol header='Cultura/ Organismo' field='culturaNome' headerClass="myfunc-default-header"
				maxWidth={130} />	

			<ListCol field="codigo" header="Nº Autorização"/>
				
			<ListCol header='Emissão CIBio' field='dataEmissaoCibio' headerClass="myfunc-default-header"
				valueFormatter={({ data }: any) => data.dataEmissaoCibio ? _generateDate(data.dataEmissaoCibio, 'Pt') : ''}
				maxWidth={130} />					
			
		</SearchBtnDialog>;
	}

	const ButtonDialogEnviarTecnico = () =>{
		return <DialogPopUp
		showMe={isCorrecaoForm}
		onClose={()=> setIsCorrecaoForm(false)}
		width50
		form={defaultForm}
		name = {`popIsCorrecao-1`}
// 		buttons={<GenericButton
// 			title={ !(entity?.id) ? "Enviar para Análise Técnico" : "Reenviar para Análise Técnico"}
// 			variant="btn-danger"
// 			type="submit"
// 			loading={loadingSubmit && (estagio === STATUS.EmAnaliseTecnico)}
// 			onClick={() => setEstagio(STATUS.EmAnaliseTecnico)} />	
// }
		><><RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
				form={defaultForm} 
				{...defaultForm.register(`observacaoRegulatorio`)} 
				name="observacaoRegulatorio"
				defaultValue={entity?.observacaoRegulatorio}
				nolabel layoutColumns={12} />

			<GenericButton
			title={ !(entity?.id) ? "Enviar para Análise Técnico" : "Reenviar para Análise Técnico"}
			variant="btn-danger"
			type="submit"
			loading={loadingSubmit && (estagio === STATUS.EmAnaliseTecnico)}
			onClick={() => setEstagio(STATUS.EmAnaliseTecnico)} />
		
		</></DialogPopUp>;
	}

	const CampoObservacao = () => {
		const _status = (entity?.status ?? STATUS.EmPreenchimento);
		if(_status === STATUS.EmPreenchimento && !entity?.lpmaBase) { return ''}
		if(_status === STATUS.Aprovado) { return ''}
		const texto = _status === STATUS.EmAnalisePeloRegulatorio ? 
						"Observações de Análise do Regulatório, a serem enviadas ao Técnico." :
						"Observações de Análise Técnico, a serem enviadas ao Regulatório.";

		return <EditField title={<span style={{fontWeight: 'bold'}}>{texto}</span>}>
		{_status === STATUS.EmAnalisePeloRegulatorio 
		|| _status === STATUS.EmPreenchimento ?
			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
			equired isInvalidCheck  layoutColumns={12} 
			 form={defaultForm}    name="observacoesRegulatorio"
			{...defaultForm.register(`observacoesRegulatorio`)}
			defaultValue={entity?.observacoesRegulatorio} nolabel/> : ''}

		{_status === STATUS.EmAnaliseTecnico ?
			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
			required isInvalidCheck  layoutColumns={12} 
			 form={defaultForm}    name="observacoes"
			{...defaultForm.register(`observacoes`)}
			defaultValue={entity?.observacoes} nolabel/> : ''}

		</EditField>
	}

	const actionsGene = (props: any) => (
		<Fragment>
			<ViewButton onClick={() => {

				setGeneToView({
					nomeConstrucao: props.data.nomeConstrucao,
					nomeGeneInteresse: props.data.nomeGeneInteresse,
					organismoDoadorGeneInteresse: props.data.organismoDoadorGeneInteresse,
					funcaoGeneInteresse: props.data.funcaoGeneInteresse,
				});

				setGridProps(props.node);

				setModalGene(true)
			}} />
		</Fragment>
	)

	const AtualizarGridMateriaisAprovados = (index: any) => { 

		var AreaSelecionada = entity!.areas![index];

		let AreaEnsaioIDInicial = 0;

		AreaSelecionada.area_Ensaio.forEach((dd: any) => {
			if (AreaEnsaioIDInicial === 0)
				AreaEnsaioIDInicial = dd.id;
		})

		const materiaisAprovadosArea = entityComunicadoMaterialAprovado.filter(x => x.area_Ensaio_ID === AreaEnsaioIDInicial);
		const materiaisPlantadosArea = entityComunicadoMaterialPlantado.filter(x => x.area_Ensaio_ID === AreaEnsaioIDInicial);

		setEntityComunicadoMaterialAprovadoGrid(materiaisAprovadosArea);
		setEntityComunicadoMaterialPlantadoGrid(materiaisPlantadosArea);

	}

	const AtualizarMateriaisAprovados = () => {

		let AreaEnsaioID: any;

		for (var i = 0; i < entityComunicadoMaterialAprovadoGrid.length; i++) {
			AreaEnsaioID = entityComunicadoMaterialAprovadoGrid[i].area_Ensaio_ID;
		}

		var arr = entityComunicadoMaterialAprovado.filter(x => x.area_Ensaio_ID !== AreaEnsaioID);

		for (var i = 0; i < entityComunicadoMaterialAprovadoGrid.length; i++) {
			arr.push(entityComunicadoMaterialAprovadoGrid[i]);
		}

		return arr;
	}

	const AtualizarMateriaisPlantados = () => {

		let AreaEnsaioID: any;

		for (var i = 0; i < entityComunicadoMaterialPlantadoGrid.length; i++)
		{
			AreaEnsaioID = entityComunicadoMaterialPlantadoGrid[i].area_Ensaio_ID;
		}

		var arr = entityComunicadoMaterialPlantado.filter(x => x.area_Ensaio_ID !== AreaEnsaioID);

		for (var i = 0; i < entityComunicadoMaterialPlantadoGrid.length; i++) {
			arr.push(entityComunicadoMaterialPlantadoGrid[i]);
		}

		return arr;
	}

	const { getValues: getValuesGene, control: controlGene, register: registerGene, errors: errorsGene, handleSubmit: handleSubmitGene, trigger: triggerGene, watch: watchGene } = useForm({
		mode: 'all',
	})


	return <EditBox<dto.RCLpmaDto> 
		title={titleForm}		 
		form={defaultForm}
		entity={entity}	
		MsgSaveSubmitSucess={msgSalvoComSucesso}
		OnSaveSubmit={submitForm}
		OnSaveSubmitErro={submitFormErro}
		OnSaveSubmitIsOk={submitFormisOK}	 
		parseButtons={parseButton}
		// loading={loadView}
		// loadingMsg={titleForm}

		>
		{ButtonSearchLPMA()}
		{ButtonSearchAMI()}		
		{entity?.id ? <>
			<TextField<dto.RCLpmaDto> isSingle form={defaultForm} 
			defaultValue={entity?.id}
			name="id" label="Nº da Solicitação" layoutColumns={2} 						
			/>
			<TextField<dto.RCLpmaDto> isSingle form={defaultForm}  
			defaultValue={entity?.numeroProcessoEAno}  
			name="numeroProcessoEAno" layoutColumns={2} label="Nº Controle" />

			<TextField<dto.RCLpmaDto> isSingle form={defaultForm} 
			name="status" label="Status" layoutColumns={4} 		
			value={STATUS_RC_LPMA.find(a=> a.s === (entity.status ?? 1))?.t}				
			/>
			<TextField<dto.RCLpmaDto> isSingle form={defaultForm} 
			name="solicitante" label="Regulatório" layoutColumns={4} 					
			/>

			{!(!entity?.observacoes) && estagio !== STATUS.EmAnaliseTecnico ?
			<RichText<dto.RCLpmaDto> enabled={false} 
			form={defaultForm} 
			name="observacoesTemp"
			required
			label="Observações enviadas pelo Técnico"
			defaultValue={entity?.observacoes}
			layoutColumns={12} />	: ''
			}
			{!(!entity?.observacoesRegulatorio) && estagio !== STATUS.EmAnalisePeloRegulatorio ?
			<RichText<dto.RCLpmaDto> enabled={false} 
			form={defaultForm} 
			required
			label="Observações enviadas pelo Regulatório"
			name="observacoesRegulatorioTemp"
			defaultValue={entity?.observacoesRegulatorio}
			layoutColumns={12} />	: ''
			}
			</>
		: ''}				


		<EditField visible={!(entity?.lpmaBase) && !loadView} title="Selecione a LPMA Aprovadas">
			{ButtonSeachView('LPMA')}
		</EditField>

		{loadView ? 
		<EditField><span id="content-loading-children"><Loading lg={12} mensagem='' /></span></EditField>
		:''}

		<EditField visible={!(!entity?.lpmaBase)} title={`Dados da LPMA - ${(entity?.tipo === 'RN35' ? 'Resolução Normatuva nº 35' : 'Resolução Normatuva nº 06')}`}>
			{(entity?.statusAtualLpma === STATUS.Cancelado) ?
			<label> LPMA CANCELADA </label>
			: ''}
		
			
			<EditField layoutColumns={12} 
				rightButton={ estagio === STATUS.EmPreenchimento ? ButtonSeachView('LPMA') : null }
			>				

				<TextField name="codigoLPMA" defaultValue={entity?.lpmaBase?.numeroProcessoEAno}  layoutColumns={2} label="Nº Controle da LPMA" enabled={false}/>
				<TextField form={defaultForm}  name="projeto" layoutColumns={4} enabled={false} label="Código do Projeto" />
				<TextField form={defaultForm}  name="tecnicoPrincipal" layoutColumns={6} enabled={false} label="Técnico(a) Principal" />
			
				<TextField form={defaultForm}  layoutColumns={12} name="titulo" label="Título" enabled={editarAll || requiredTec}  isInvalidCheck={!location?.state?.readonly}/>
			</EditField>	
			
			<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaInicioDe" maxDate={defaultForm.watch().dataPrevistaInicioAte}  label="Data de Início De:" layoutColumns={3} form={defaultForm} enabled={editarAll || requiredTec} ></DateField> 
			<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaInicioAte" minDate={defaultForm.watch().dataPrevistaInicioDe}  label="Até" layoutColumns={2} form={defaultForm} enabled={editarAll || requiredTec} ></DateField>   
			<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaFimDe" minDate={defaultForm.watch().dataPrevistaInicioAte} maxDate={defaultForm.watch().dataPrevistaFimAte}  label="Data de Término De:" layoutColumns={3} form={defaultForm} enabled={editarAll || requiredTec} ></DateField>  
			<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaFimAte" minDate={defaultForm.watch().dataPrevistaFimDe}  label="Até" layoutColumns={2} form={defaultForm} enabled={editarAll || requiredTec} ></DateField>  

			<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaColheitaInicio" maxDate={defaultForm.watch().dataPrevistaColheitaInicio} label="Data Colheita de Início De:" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>
			<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaColheitaTermino" minDate={defaultForm.watch().dataPrevistaColheitaTermino} label="Até" layoutColumns={3} form={defaultForm} enabled={editarAll} ></DateField>   
			{/*<DateField showMonthYearPicker dateFormat="MMMM yyyy" name="dataPrevistaColheira" label="Data da colheita" layoutColumns={2} form={defaultForm} enabled={editarAll || requiredTec} ></DateField>  */}
			{/* IF RN06 */}
			{entity?.tipo === "RN35"?<>
			<DateField showYearPicker dateFormat="yyyy" name="anoSafra" label="Ano da Safra" layoutColumns={3} form={defaultForm} enabled={editarAll || requiredTec} ></DateField>  
			
			<DropDown  name="importacao" label="LPMA destinada a importação?" layoutColumns={3} form={defaultForm} options={[{value:'1', label: 'Sim'}, {value: '0', label: 'Não'}]}
			enabled={editarAll || requiredTec} isClearable={editarAll} placeholder="Importação?" isInvalidCheck={!location?.state?.readonly}
			/>	
			</>:''}	

			{/* <TextArea form={defaultForm} name="objetivo" label="Objetivo" layoutColumns={12} rows={3} enabled={editarAll || requiredTec} />       */}
			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
			form={defaultForm} 
			{...defaultForm.register(`objetivo`)} 
			name="objetivo"
			defaultValue={entity?.objetivo}
			label="Objetivo" layoutColumns={12} />

			<Grid<dto.RCLpmaDto, lpma.Lpma_RN_PotencialConstrucoes>
				form={defaultForm}
				{...defaultForm.register(`construcoes`)} 
				name="construcoes" 
				defaultValue={entity?.construcoes}				
				style={{ height: '210px', width: '100%' }}
					label="Construções que foram avaliadas nessa LPMA" 
				layoutColumns={12}
				enabled={editarAll || requiredTec} 						
				collumns={[
					{header: 'Nº Ref. LPMA', field: 'processoCTNBio', 
					showOnModal: true, custumControls : <TextField name="processoCTNBio" layoutColumns={20} /> },

					{header: 'Nome da Construção', field: 'nomeContrucao', 
					showOnModal: true, custumControls : <TextField name="nomeContrucao" layoutColumns={20} /> },
					
					{header: 'Notas', field: 'notas',
					showOnModal: true, custumControls: <RichText enabled={editarAll || requiredTec}
															name="notas" layoutColumns={12} />
					},
				]}
				></Grid> 					

			<Tabs<dto.RCLpmaDto, lpma.LpmaRNEnsaio>
				location={location}
				label="Locais de Ensaio"
				form={defaultForm} 
				enabled={editarAll || requiredTec} 				
				defaultValue={entity?.ensaios}
				isInvalidCheck={editarAll}
				name="ensaios"
				msgTabRemoveTitle="Remover ensaio"
				msgTabRemovePrompt="Confirmar remoção de Ensaio"
				addNewItem={()=>{ return { descricao: 'NOVO ENSAIO', uid: '', id: 0 } as LpmaRNEnsaio }}
				titleWatchField="descricao"	
				indicesRemoved={(idxRmv:any[])=>{ setItemRemovedEnsaios(idxRmv)  }}
				renderItem={(item: any, index:any)=>{

					return  <Form.Row className="row-custom">
								<DataHide form={defaultForm}  binds={[
									{defaultValue: item.id, name: `ensaios.${index}.id`},
									{defaultValue: item.uid, name: `ensaios.${index}.uid`}
								]}/>
								<TextField isInvalidCheck={editarAll} enabled={editarAll || requiredTec}  form={defaultForm} {...defaultForm.register(`ensaios.${index}.descricao`)}    name={`ensaios.${index}.descricao`} defaultValue={item.descricao} label="Descrição" layoutColumns={2} />      
	
								<TextFields label="Dimensões do Croqui [Largura x Comprimento]" layoutColumns={6} >
									<TField isInvalidCheck={editarAll} placeholder="Largura"  layoutChildColumns={6} enabled={editarAll || requiredTec} isNumer={true} form={defaultForm} name={`ensaios.${index}.largura`} suffix=" m (larg.)" defaultValue={item.largura}/>
									<TField isInvalidCheck={editarAll} placeholder="Comprimento"  layoutChildColumns={6} enabled={editarAll || requiredTec} isNumer={true} form={defaultForm} name={`ensaios.${index}.comprimento`} suffix=" m (comp.)" defaultValue={item.comprimento}/>
								</TextFields>
				
								<TextFields label="Dimensão da Parcela [Largura x Comprimento]" layoutColumns={6}>
									<TField  isInvalidCheck={editarAll} placeholder="Largura"  layoutChildColumns={6} enabled={editarAll || requiredTec} isNumer={true} form={defaultForm} name={`ensaios.${index}.parcelaLargura`} suffix=" m (larg.)" defaultValue={item.parcelaLargura}/>
									<TField  isInvalidCheck={editarAll} placeholder="Comprimento"  layoutChildColumns={6} enabled={editarAll || requiredTec} isNumer={true} form={defaultForm} name={`ensaios.${index}.parcelaComprimento`} suffix=" m (comp.)" defaultValue={item.parcelaComprimento}/>
								</TextFields>
								
								<TextField isInvalidCheck={editarAll} label="Densidade" placeholder="Densidade" suffix=" kg/ha"  isNumer={true}  enabled={editarAll || requiredTec} layoutColumns={2}  form={defaultForm} name={`ensaios.${index}.densidade`} defaultValue={item.densidade}  />
								<TextField isInvalidCheck={editarAll} label="Número de Repetições" placeholder="Número de Repetições" prefix="Nº " isNumer={true}  enabled={editarAll || requiredTec} layoutColumns={3}  form={defaultForm} name={`ensaios.${index}.numeroRepeticoes`} defaultValue={item.numeroRepeticoes}   />
								<TextField isInvalidCheck={editarAll} label="% de Perda de Sementes" placeholder="Percentual de Perda" isNumer={true}  enabled={editarAll || requiredTec} layoutColumns={3}  form={defaultForm} name={`ensaios.${index}.percentualSemente`} defaultValue={item.percentualSemente}  suffix=" %" />

								<DataHide form={defaultForm}  binds={[
								{defaultValue: item.uid, name: `ensaios.${index}.uid`},
								]}/>

								{/* <TextField enabled={editarAll || requiredTec} form={defaultForm} name={`ensaios.${index}.material`} defaultValue={item.material} label="Material do Ensaio" layoutColumns={6} />
								<TextField enabled={editarAll || requiredTec} form={defaultForm} name={`ensaios.${index}.delineamentoExperimental`} defaultValue={item.delineamentoExperimental} label="Delineamento Experimental" layoutColumns={6} />
								<TextField enabled={editarAll || requiredTec} form={defaultForm} name={`ensaios.${index}.objetivo`} defaultValue={item.objetivo} label="Objetivo do Ensaio" layoutColumns={12} /> */}
								
								<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
								form={defaultForm} 
								{...defaultForm.register(`ensaios.${index}.objetivo`)} 
								name={`ensaios.${index}.objetivo`}
								defaultValue={item.objetivo}
								label="Objetivo do Ensaio" layoutColumns={12} />

								<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
								form={defaultForm} 
								{...defaultForm.register(`ensaios.${index}.material`)} 
								name={`ensaios.${index}.material`}
								defaultValue={item.material}
								label="Material do Ensaio" layoutColumns={6} />

								<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
								form={defaultForm} 
								{...defaultForm.register(`ensaios.${index}.delineamentoExperimental`)} 
								name={`ensaios.${index}.delineamentoExperimental`}
								defaultValue={item.delineamentoExperimental}
								label="Delineamento Experimental" layoutColumns={6} />
								
																		
								<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} 
								form={defaultForm} 
								{...defaultForm.register(`ensaios.${index}.tratosCulturais`)} 
								name={`ensaios.${index}.tratosCulturais`}
								defaultValue={item.tratosCulturais}
								label="Tratos Culturais" layoutColumns={12} />

								<Grid<dto.RCLpmaDto, LpmaRNEnsaioTratamento>
								form={defaultForm}
								// {...defaultForm.register(`ensaios.${index}.avaliacaoAgronomica`)} 
								name={`ensaios.${index}.tratamentos`} 
								defaultValue={item.tratamentos}

								isInvalidCheck={!location?.state?.readonly}
								style={{ height: '210px', width: '100%' }}
								label="Protocolo Experimental - Tratamento" 
								layoutColumns={12}
								enabled={editarAll || requiredTec} 
								DropList={initData?.itemsTipoTratamento?.map(e=> e.nome)}
								collumns={[
									{header: 'Tipo de Protocolo', field: 'tipoTratamento', 
										gridValueFormatter: (event:any)=>{ return event.data?.tipoTratamento?.label },
										showOnModal: true,  minWidth: 100,
										dropListGetData: (data:any, eindex:any, isNew: any)=>{  	
												if(!data) return null;												
												const it = (initData?.itemsTipoTratamento as any[])[data.index];
												return (isNew ? Object.assign({ value: it.id, label: it.nome }) : null );													
										},
										custumControls:  
										<DropDown layoutColumns={6}  enabled={false}
										isClearable={editarAll}
										placeholder={"Tipo de Tratamento"}
										options={initData?.itemsTipoTratamento?.map(i => Object.assign({ value: i.id, label: i.nome }))}  /> 
									},
									{header: 'Descrição', field: 'descricao', 
									showOnModal: true, custumControls : <TextField layoutColumns={6} /> },
									{header: 'Dose(g)', field: 'dose', minWidth: 10,
									showOnModal: true, custumControls : <TextField isNumer layoutColumns={6} /> },
									{header: 'Estágio da Aplicação', field: 'estagioAplicacao',  minWidth: 200,
									showOnModal: true, custumControls : <TextField layoutColumns={6} /> },
								]}
								></Grid>

								<RichText<LpmaRNEnsaio> enabled={editarAll || requiredTec} 
								form={defaultForm} 
								{...defaultForm.register(`ensaios.${index}.tratamento`)} 
								name={`ensaios.${index}.tratamento`}
								defaultValue={item.tratamento}
								label="Protocolo Experimental - Observações de Tratamento" layoutColumns={12} />
		
								<RichText<LpmaRNEnsaio> enabled={editarAll || requiredTec} 
								form={defaultForm} 
								{...defaultForm.register(`ensaios.${index}.avaliacaoAgronomicaTexto`)} 
								name={`ensaios.${index}.avaliacaoAgronomicaTexto`}
								defaultValue={item.avaliacaoAgronomicaTexto}
								label="Avaliações Agronomica - Observações" layoutColumns={12} />

							</Form.Row>
				}}
			></Tabs>

			&nbsp;&nbsp;						
			<Tabs<dto.RCLpmaDto, lpma.LpmaRNArea>
			defaultValue={entity?.areas}
			location={location}
			isInvalidCheck={editarAll}
			label="Areas"
			form={defaultForm} 
			enabled={editarAll || requiredTec}
			name="areas"
			msgTabRemoveTitle="Remover área"
			msgTabRemovePrompt="Confirmar remoção de Área"
			addNewItem={()=>{ return { descricao: 'AREA', uid: '', id: 0 } as lpma.LpmaRNArea }}
			titleWatch={(index: any) => { 
				var localname = (defaultForm.watch(`areas.${index}.local`) as any)?.label ?? '';
				return `Área: ${localname}`;
			}}
			indicesRemoved={(idxRmv:any[])=>{ setItemRemovedAreas(idxRmv)  }}
			renderItem={(item: any, index:any)=>{
				return  <Form.Row className="row-custom">
						<DataHide form={defaultForm}  binds={[
							{defaultValue: item.id, name: `areas.${index}.id`},
							{defaultValue: item.uid, name: `areas.${index}.uid`}
						]}/>

						<DropDown enabled={editarAll || requiredTec} form={defaultForm} name={`areas.${index}.local`} label="Local" layoutColumns={3} 
						isClearable={editarAll} defaultValue={item.local}
						placeholder={"Selecione um Local"}
						options={initData?.locais?.map(i => Object.assign({ value: i.id, label: i.nomeDaFazenda }))}  
						isInvalidCheck={!location?.state?.readonly}/>

						<DropDown enabled={editarAll || requiredTec} form={defaultForm} name={`areas.${index}.tecnicoResponsavel`} label="Técnico Responsável" layoutColumns={3} 
						isClearable={editarAll}
						placeholder={"Selecione um Técnico Responsável"}
						options={initData?.equipeTecnica?.map(i => Object.assign({ value: i.id, label: i.nome }))}  
						isInvalidCheck={!location?.state?.readonly}/>

						<DropDown enabled={editarAll || requiredTec} form={defaultForm}
						name={`areas.${index}.areaEnsaio`} 
						label="Ensaios Associados a está Área" layoutColumns={6} 
						isClearable={editarAll}
						placeholder={"Selecione Ensaios"}
						isMulti={true}
						options={EnsaiosList().map((i:any) => Object.assign({ value: i.uid, label: i.descricao }))}  />

						<ToggleBox enabled={editarAll || requiredTec} form={defaultForm}
							name={`areas.${index}.areaUtilizada`} defaultChecked={item.areaUtilizada}
							label={<span style={{ fontWeight: 'bolder' }}>{STRINGS_RCLPMA.LABEL_RCAREAUTILIZADA}</span>} />

						<DateField name={`areas.${index}.dataPlantio`}  maxDate={defaultForm.watch(`areas.${index}.dataConclusao`)}  
						label="Data do Plantio:" layoutColumns={3} form={defaultForm} enabled={editarAll || requiredTec} /> 

						<DateField name={`areas.${index}.dataDestruicao`}  maxDate={defaultForm.watch(`areas.${index}.dataConclusao`)}  
						label="Data da Destruição:" layoutColumns={3} form={defaultForm} enabled={editarAll || requiredTec} /> 
							
						<DateField name={`areas.${index}.dataConclusao`}  minDate={defaultForm.watch(`areas.${index}.dataPlantio`)}  
						label="Data da Conclusão:" layoutColumns={3} form={defaultForm} enabled={editarAll || requiredTec} /> 
	
						<FilesUpload form={defaultForm} isInvalidCheck={editarAll} enabled={editarAll || requiredTec} 
						label="Figura (Arquivo de Imagem)" 
						iconView="ANEXO"
						isAdicionalText={true}
						adicionalText="Legenda da Imagem"
						name={`areas.${index}.imagemFileTemp`} 
						nameAdicionalText={`areas.${index}.imagemFileLegenda`} 
						nameGuid={`areas.${index}.imagemFileGuid`}			
						textButton="Inserir Arquivo"  layoutColumns={8} 	
						defaultValue={item?.imagemFile}
						nameValidCheck={`areas.${index}.imagemFileLegenda`} 
						accept="image/png, image/jpeg"	
						ClickFile={(file:any, isLocal: boolean)=>{
							if(!isLocal){
								RCLpmaServiceApi.DownloadFile(entity?.id, 'imagem', file.guid, file.name)
							}				
						}}
						/>				

						<Grid<dto.RCLpmaDto, Lpma_RN06_Comunicados_Materiais_Aprovados>
							form={defaultForm}
							// {...defaultForm.register(`ensaios.${index}.avaliacaoAgronomica`)} 
							name={`areas.${index}.materiaisAprovados`}
							defaultValue={entityComunicadoMaterialAprovadoGrid}

							isInvalidCheck={!location?.state?.readonly}
							style={{ height: '210px', width: '100%' }}
							label="Materiais Aprovados" 
							layoutColumns={12}
							enabled={editarAll || requiredTec} 
							collumns={[
								{header: 'Material', field: 'material', 
								showOnModal: true, custumControls : <TextField layoutColumns={6} /> },
								{header: 'Número Entradas', field: 'numeroEntradas', minWidth: 10,
								showOnModal: true, custumControls : <TextField isNumer layoutColumns={6} /> },
								{header: 'Número Tratamentos', field: 'numeroTratamentos', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} />	},
								{header: 'Número Repetições', field: 'numeroRepeticoes', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
								{header: 'Total Parcelas', field: 'totalParcelas', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
								{header: 'Área Parcela', field: 'areaParcela', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
								{header: 'Área Total', field: 'areaTotal', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
							]}
							></Grid>

						<Grid<dto.RCLpmaDto, Lpma_RN06_Comunicados_Materiais_Plantados>
							form={defaultForm}
							// {...defaultForm.register(`ensaios.${index}.avaliacaoAgronomica`)} 
							name={`areas.${index}.materiaisPlantados`}
							defaultValue={entityComunicadoMaterialPlantadoGrid}

							isInvalidCheck={!location?.state?.readonly}
							style={{ height: '210px', width: '100%' }}
							label="Materiais Plantados" 
							layoutColumns={12}
							enabled={editarAll || requiredTec} 
							collumns={[
								{header: 'Material', field: 'material', 
								showOnModal: true, custumControls : <TextField layoutColumns={6} /> },
								{header: 'Número Entradas', field: 'numeroEntradas', minWidth: 10,
								showOnModal: true, custumControls : <TextField isNumer layoutColumns={6} /> },
								{header: 'Número Tratamentos', field: 'numeroTratamentos', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} />	},
								{header: 'Número Repetições', field: 'numeroRepeticoes', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
								{header: 'Total Parcelas', field: 'totalParcelas', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
								{header: 'Área Parcela', field: 'areaParcela', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },
								{header: 'Área Total', field: 'areaTotal', minWidth: 10,
								showOnModal: true, custumControls: <TextField isNumer layoutColumns={6} /> },

							]}
							></Grid>
				
						</Form.Row>
			}}
			></Tabs>	

			<EditField visible={!(!entity?.lpmaBase)} >
				<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec} layoutColumns={12}
					form={defaultForm} name="justificativaAreas"
					{...defaultForm.register(`justificativaAreas`)}
					defaultValue={entity?.justificativaAreas} label="Justificativa para uso ou não das Áreas" />
			</EditField>
		</EditField>	

		<EditField visible={!(!entity?.lpmaBase)} title="Genes de Interesse">
			<div className="ag-theme-alpine" style={{ height: '210px', width: '100%' }}>
				<AgGridReact
					rowData={entity?.genesinteresse}
					defaultColDef={{ flex: 1, sortable: true, resizable: true, cellStyle: { justifyContent: 'center', alignItems: 'center' } }}
					gridOptions={{ headerHeight: 80 }}
					frameworkComponents={{
						actions: actionsGene,
					}}
				>
					<AgGridColumn
						headerName='Construção'
						valueGetter={({ data }: any) => data.nomeConstrucao}
						cellStyle={{
							justifyContent: 'left'
						}}
						autoHeight
						wrapText
					/>
					<AgGridColumn
						headerName='Gene'
						valueGetter={({ data }: any) => data.nomeGeneInteresse}
						cellStyle={{
							justifyContent: 'left'
						}}
						autoHeight
						wrapText
					/>
					<AgGridColumn
						headerName='Organismo Doador'
						valueGetter={({ data }: any) => data.organismoDoadorGeneInteresse}
						cellStyle={{
							justifyContent: 'left'
						}}

					/>
					<AgGridColumn
						headerName='Função Resumida'
						valueGetter={({ data }: any) => data.funcaoResumida}
						cellStyle={{
							justifyContent: 'left'
						}}
						autoHeight
						wrapText
					/>
					<AgGridColumn
						headerName="Ações"
						cellRenderer="actions"
						maxWidth={100}
					/>
				</AgGridReact>
			</div>
		</EditField>

		{/*<EditField visible={!(!entity?.lpmaBase)} title="Genes de Interesse">*/}
		{/*	<Grid<dto.RCLpmaDto, lpma.LPMA_GenesInteresseGrid>*/}
		{/*		form={defaultForm}*/}
		{/*		{...defaultForm.register(`genesinteresse`)}*/}
		{/*		name="genesinteresse"*/}
		{/*		defaultValue={entity?.genesinteresse}*/}
		{/*		style={{ height: '210px', width: '100%' }}*/}
		{/*		label="Genes de Interesse"*/}
		{/*		layoutColumns={12}*/}
		{/*		enabled={editarAll || requiredTec}*/}
		{/*		viewOnly={true}*/}
		{/*		collumns={[*/}
		{/*			{*/}
		{/*				header: 'UID', field: 'uid',*/}
		{/*				showOnGrid: false, showOnModal: false, custumControls: <TextField name="uid" layoutColumns={20} />*/}
		{/*			},*/}
		{/*			{*/}
		{/*				header: 'Construção', field: 'nomeConstrucao',*/}
		{/*				showOnModal: true, custumControls: <TextField name="nomeConstrucao" layoutColumns={20} />*/}
		{/*			},*/}

		{/*			{*/}
		{/*				header: 'Nome', field: 'nomeGeneInteresse',*/}
		{/*				showOnModal: true, custumControls: <TextField name="nomeGeneInteresse" layoutColumns={20} />*/}
		{/*			},*/}

		{/*			{*/}
		{/*				header: 'Organismo Doador', field: 'organismoDoadorGeneInteresse',*/}
		{/*				showOnModal: true, custumControls: <TextField name="organismoDoadorGeneInteresse" layoutColumns={20} />*/}
		{/*			},*/}

		{/*			{*/}
		{/*				header: 'Função', field: 'funcaoGeneInteresse', */}
		{/*				showOnGrid: false, showOnModal: true, custumControls: <RichText name="funcaoGeneInteresse" layoutColumns={12} />*/}
		{/*			},*/}

		{/*			{*/}
		{/*				header: 'Função Resumida', field: 'funcaoResumida',  */}
		{/*				showOnModal: false, custumControls: <RichText name="funcaoResumida" layoutColumns={12} />*/}
		{/*			},*/}
		{/*		]}*/}
		{/*	></Grid> 					*/}

		{/*</EditField>*/}

		<EditField visible={!(entity?.amiBase) && !(!entity?.lpmaBase)} title="Selecione a AMI">
			{ButtonSeachView('AMI')}	
			{!eventListAMI ? 		
			<span id="content-loading-eventListAMI"><Loading lg={12} mensagem='' /></span>
			:
			<Grid<dto.RCLpmaDto, ami.EventListAMI>
				{...defaultForm.register(`eventos`)} 
				isInvalidCheck={editarAll} 
				form={defaultForm}
				style={{ height: '210px', width: '100%' }}
				name="eventos" label="Informar a quantidade de OGM proveniente desta liberação" 
				layoutColumns={12}
				enabled={editarAll || requiredTec} 
				defaultValue={amiEventos}
				validarCampos={['evento', 'material', 'qtdTransportada', 'unidadeMedidaTransportada', 'material']}
				validarValores={(camposValidados:any[], valores:any)=>{
					const campos = {...valores}
					//for(let key in campos){
					for(var i = 0, t = camposValidados.length; i < t; i++){
						const key = camposValidados[i];
						if(campos[key] === undefined || campos[key] === null) { return false;}
						if(key === 'qtdTransportada'){
							if(campos[key].trim() === ''){ return false;}
						}
					}					
					return true;
				}}
				validarValoresMsg="Campos são obrigatórios."
				collumns={[
					{showOnModal: false, showOnGrid: false, field: 'uid' , header: '' },

					{showOnModal: false, showOnGrid: false, field: 'eventoId', 
					gridValueFormatter: (event:any)=>{ return event.data?.tecnico?.id }, header: '' },

					{header: 'AMI - Nº Autorização', field: 'amiNumAutorizacao', 
					showOnModal: true, minWidth: 200, enabled: false,
					gridValueFormatter: ({data}:any)=>{ 
						return (data?.ami?.codigo ?? '-');
					},
					custumControls: <TextField layoutColumns={6} /> },

					{
						header: 'Objetivo', field: 'amiObjetivo',
						showOnModal: true, minWidth: 200, enabled: false,
						gridValueFormatter: ({ data }: any) => {
							return (data?.ami?.objetivoUtilizacao ?? '-');
						},
						custumControls: <TextField layoutColumns={6} />
					},

					{header: 'Evento', field: 'evento', showOnModal: true, minWidth: 150,
					gridValueFormatter: ({data}:any)=>{ 
						return data?.evento?.nome ?? ''
					},
					custumControls: <DropDown layoutColumns={12}  isClearable={editarAll}
							placeholder="Selecione um evento"
							options={autDataDefault?.events?.map((event:any) => Object.assign({...event, value: event.id, label: event.nome }))}  /> 
					},
					{header: 'Quantidade Autorizada', field: 'qtdAutorizada',showOnModal: true, enabled: false,
					gridValueFormatter: ({data}:any)=>{  
						if(!data.qtdAutorizada) { return "-"; }

						let undT = autDataDefault?.unidadeMedida?.find((e:any)=> e.key === data?.unidadeMedidaAutorizada?.value)?.value;
						if(data?.unidadeMedidaAutorizada?.value === 4) {  undT = data?.unidMedAutorizadaOutros ?? undT; }
						return `${data.qtdAutorizada} ${undT}`
					},
					custumControls: <TextField isNumer layoutColumns={6} /> 
					},
					
					{header: 'Unidade Medida', field: 'unidadeMedidaAutorizada', showOnModal: true, showOnGrid: false, enabled: false,
					custumControls: <DropDown changedValue layoutColumns={3}  isClearable={editarAll}
							placeholder="Uni."
							options={autDataDefault?.unidadeMedida?.map((i:any) => Object.assign({ value: i.key, label: i.value }))}  /> 
					},

					{showOnModal: false, showOnGrid: false, field: 'unidMedAutorizadaOutros' , header: 'Outros',
					showOnModalCondificional: true, enabled: false,
					Condificional:({data, form}:any)=> {
						if(!data.unidMedAutorizadaOutros) { return "-"; }
						const und = form.watch('unidadeMedidaAutorizada');
						return (und?.value === 4)
					}, custumControls: <TextField layoutColumns={3} /> },

					{header: 'Quantidade Transportada', field: 'qtdTransportada', showOnModal: true,
					gridValueFormatter: ({data}:any)=>{  
						if(!data.qtdTransportada) { return "-"; }

						let undT = autDataDefault?.unidadeMedida?.find((e:any)=> e.key === data?.unidadeMedidaTransportada?.value)?.value;
						if(data?.unidadeMedidaTransportada?.value === 4) {  undT = data?.unidMedTranspOutros ?? undT; }
						return `${data.qtdTransportada} ${undT}`
					},
					custumControls: <TextField isNumer layoutColumns={6} /> 
					}
					,
					{header: 'Unidade Medida', field: 'unidadeMedidaTransportada', showOnModal: true, showOnGrid: false,
					custumControls: <DropDown changedValue layoutColumns={3} isClearable={editarAll}
							placeholder="Uni."
							options={autDataDefault?.unidadeMedida?.map((i:any) => Object.assign({ value: i.key, label: i.value }))}  /> 
					},

					{showOnModal: false, showOnGrid: false, field: 'unidMedTranspOutros' , header: 'Outros',
					showOnModalCondificional: true,
					Condificional:({data, form}:any)=> {
						const und = form.watch('unidadeMedidaTransportada');
						return (und?.value === 4)
					} , custumControls: <TextField layoutColumns={3} /> },

					{header: 'Material', field: 'material', showOnModal: true,
					gridValueFormatter: ({data}:any)=>{ 
						let undT = autDataDefault?.material?.find((e:any)=> e.key === data?.material?.value)?.value
						if(data?.material?.value === 7) {  undT = data?.materialOutros ?? undT; }
						return undT;
						//autDataDefault?.material?.find((e:any)=> e.key === data?.material?.value)?.value
					},
					custumControls: <DropDown changedValue layoutColumns={12} isClearable={editarAll}
							placeholder="Selecione um material"
							options={autDataDefault?.material?.map((i:any) => Object.assign({ value: i.key, label: i.value }))}  /> 
					},

					{showOnModal: false, showOnGrid: false, field: 'materialOutros' , header: 'Outros',
					showOnModalCondificional: true,
					Condificional:({form, data}:any)=> {
						const und = form.watch('material');
						return (und?.value === 7)
					} , custumControls: <TextField  layoutColumns={3} /> },

					
				]}
			></Grid>}

			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="ogmProvenienteAMI"
				{...defaultForm.register(`ogmProvenienteAMI`)}
				defaultValue={entity?.ogmProvenienteAMI} 
				label={STRINGS_RCLPMA.LABEL_SESSAO_20}/>
		</EditField>		

		<EditField visible={!(!entity?.lpmaBase)} title="Dados Complementares">
			<TextField form={defaultForm}  name="risco" layoutColumns={6} enabled={editarAll || requiredTec} 
			label="Classificação de Risco" defaultValue="Classe de Risco 1 (NB-I)" />
			
			<RichText<dto.RCLpmaDto> required={requiredTec} enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="ogmLiberado"
				{...defaultForm.register(`ogmLiberado`)}
				defaultValue={entity?.ogmLiberado} label="OGM liberado"/>

			<RichText<dto.RCLpmaDto> required={requiredTec} enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="alteracoesGeneticas"
				{...defaultForm.register(`alteracoesGeneticas`)}
				defaultValue={entity?.alteracoesGeneticas} label={STRINGS_RCLPMA.LABEL_SESSAO_10}/>

			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="medidasBiosseguranca"
				{...defaultForm.register(`medidasBiosseguranca`)}
				defaultValue={entity?.medidasBiosseguranca} label={STRINGS_RCLPMA.LABEL_SESSAO_16}/>

			<RichText<dto.RCLpmaDto> required={requiredTec} enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="monitoramento"
				{...defaultForm.register(`monitoramento`)}
				defaultValue={entity?.monitoramento} label={STRINGS_RCLPMA.LABEL_SESSAO_17}/>
			
			
			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="efeitosInesperados"
				{...defaultForm.register(`efeitosInesperados`)}
				defaultValue={entity?.efeitosInesperados} 
				label={STRINGS_RCLPMA.LABEL_SESSAO_19}/>

			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="resumoObjetivos"
				{...defaultForm.register(`resumoObjetivos`)}
				defaultValue={entity?.resumoObjetivos} 
				label={STRINGS_RCLPMA.LABEL_SESSAO_18}/>

			<ToggleBox enabled={editarAll || requiredTec} form={defaultForm} name="resumoResultadosConfidencial"
				defaultChecked={entity?.resumoResultadosConfidencial} label={<span style={{ fontWeight: 'bolder' }}>{STRINGS_RCLPMA.LABEL_CONFIDENC}</span>} />
		
			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="resumoResultados"
				{...defaultForm.register(`resumoResultados`)}
				defaultValue={entity?.resumoResultados} 
				label={<> {STRINGS_RCLPMA.LABEL_SESSAO_18}: <span style={{fontWeight: 'bolder'}}>Resultados</span></>}
				/>

			<FilesUpload form={defaultForm} isInvalidCheck={editarAll}  enabled={editarAll || requiredTec} 
			iconView="ANEXO"
			label="Imagens ou Arquivo PDF" 			
			isAdicionalText={true}
			adicionalText="Legenda da Imagem"
			name={`imagemTecnicoTemp`} 
			nameFile="imagemTecnicoName"
			nameAdicionalText={`imagemTecnicoLegenda`} 
			nameGuid={`imagemTecnicoGuid`}			
			textButton="Inserir Arquivo"  layoutColumns={6} 	
			defaultValue={entity?.imagemTecnico}
			nameValidCheck={`imagemTecnicoLegenda`} 
			accept="image/png, image/jpeg, application/pdf"	
			ClickFile={(file:any, isLocal: boolean)=>{
				if(!isLocal){
					RCLpmaServiceApi.DownloadFile(entity?.id, 'anexo', file.guid, file.name)
				}				
			}}
			/>

			<FilesUpload form={defaultForm} isInvalidCheck={editarAll}  enabled={editarAll || requiredTec} 
			iconView="ANEXO"
			label="Anexos (Arquivo de PDF ou Imagens)" 			
			isAdicionalText={true}
			adicionalText="Legenda da Imagem"
			name={`anexoTecnicoTemp`} 
			nameFile="anexoTecnicoName"
			nameAdicionalText={`anexoTecnicoLegenda`} 
			nameGuid={`anexoTecnicoGuid`}			
			textButton="Inserir Arquivo"  layoutColumns={6} 	
			defaultValue={entity?.anexoTecnico}
			nameValidCheck={`anexoTecnicoLegenda`} 
			accept="image/png, image/jpeg, application/pdf"	
			ClickFile={(file:any, isLocal: boolean)=>{
				if(!isLocal){
					RCLpmaServiceApi.DownloadFile(entity?.id, 'anexo', file.guid, file.name)
				}				
			}}
			/>

			<ToggleBox enabled={editarAll || requiredTec} form={defaultForm}  name="fiscalizacaoConfidencial" defaultChecked={entity?.fiscalizacaoConfidencial} label={<span style={{fontWeight: 'bolder'}}>{STRINGS_RCLPMA.LABEL_CONFIDENC}</span>}  />
		
			<RichText<dto.RCLpmaDto> enabled={editarAll || requiredTec}   layoutColumns={12} 
				 form={defaultForm}    name="fiscalizacao"
				{...defaultForm.register(`fiscalizacao`)}
				defaultValue={entity?.fiscalizacao} 
				label={STRINGS_RCLPMA.LABEL_SESSAO_21}/>

			<FilesUpload form={defaultForm} isInvalidCheck={editarAll} enabled={editarAll || requiredTec} 
			label="Imagens ou Arquivo PDF" 	
			iconView="ANEXO"
			isAdicionalText={true}
			adicionalText="Legenda da Imagem"
			name={`imagemFiscalizacaoTemp`} 
			nameFile="imagemFiscalizacaoName"
			nameAdicionalText={`imagemFiscalizacaoLegenda`} 
			nameGuid={`imagemFiscalizacaoGuid`}			
			textButton="Inserir Arquivo"  layoutColumns={6} 	
			defaultValue={entity?.imagemFiscalizacao}
			nameValidCheck={`imagemFiscalizacaoLegenda`} 
			accept="image/png, image/jpeg, application/pdf"	
			ClickFile={(file:any, isLocal: boolean)=>{
				if(!isLocal){
					RCLpmaServiceApi.DownloadFile(entity?.id, 'anexo', file.guid, file.name)
				}				
			}}
			/>

			<FilesUpload form={defaultForm} isInvalidCheck={editarAll} enabled={editarAll || requiredTec} 
			label="Anexos (Arquivo de PDF ou Imagens)" 
			iconView="ANEXO"
			isAdicionalText={true}
			adicionalText="Legenda da Imagem"
			name={`anexoFiscalizacaoTemp`} 
			nameFile="anexoFiscalizacaoName"
			nameAdicionalText={`anexoFiscalizacaoLegenda`} 
			nameGuid={`anexoFiscalizacaoGuid`}			
			textButton="Inserir Arquivo"  layoutColumns={6} 	
			defaultValue={entity?.anexoFiscalizacao}
			nameValidCheck={`anexoFiscalizacaoLegenda`} 
			accept="image/png, image/jpeg, application/pdf"	
			ClickFile={(file:any, isLocal: boolean)=>{
				if(!isLocal){
					RCLpmaServiceApi.DownloadFile(entity?.id, 'anexo', file.guid, file.name)
				}				
			}}
			/>
		</EditField>	
		
		{ButtonDialogEnviarTecnico()}
		{CampoObservacao()}		

		<If condition={modalGene}>
			<Modal
				show={true}
				onHide={() => setModalGene(false)}
				title={"GENE DE INTERESSE"} 
			>
				<Form autoComplete="off">
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Construção
							</Form.Label>
							<Form.Control
								ref={registerGene}
								name="nomeConstrucao"
								defaultValue={geneToView.nomeConstrucao}
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Gene de Interesse
							</Form.Label>
							<Form.Control
								ref={registerGene}
								name="nomeGeneInteresse"
								defaultValue={geneToView.nomeGeneInteresse}
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Organismo Doador
							</Form.Label>
							<Form.Control
								ref={registerGene}
								name="organismoDoadorGeneInteresse"
								defaultValue={geneToView.organismoDoadorGeneInteresse}
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row className="row-custom">
						<Form.Group as={Col}>
							<Form.Label>
								Função
							</Form.Label>
							<Form.Control
								ref={registerGene}
								name="funcaoGeneInteresse"
								defaultValue={geneToView.funcaoGeneInteresse}
								as="textarea"
								rows={10}
								readOnly
							/>
						</Form.Group>
					</Form.Row>
					<div className="button-position">
						<Button
							title="Fechar"
							contained={false}
							type="button"
							onClick={() => setModalGene(false)}
						/>
					</div>
				</Form>
			</Modal>
		</If>

	</EditBox>;
}
 