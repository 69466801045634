import React,  { useContext } from "react";
import api, { apiFile , apiFileDownload } from "../../services/api"
import { 
	  PERMISSIONS as RCLPMA_PERMISSIONS,
    isCheck as isRCLPMACheckRoles
} from '../../users/usersPermissions/usersPermissionsRCLPMA';
import { DecodedToken } from "../../models/user";
import jwtDecode from "jwt-decode";
import { Context } from '../../context/AuthContext'
import * as lpmaDto from "../../models/modelsLPMA";
import { RCLpmaDto, RCLpma_Comunicados_Datas, RCLpma_Comunicados_Materiais_Aprovados, RCLpma_Comunicados_Materiais_Plantados } from "../../models/modelsRCLPMA";
import { AMIByIdToEdit, AMISearchResult, CultureResult, Enum, EventResult, UnityStationResult } from "../../models/modelsAuthorizationsCIBIO";
import { STATUS_CIBIO } from "../../util/authorizationsCIBIO/status";


export default class RCLpmaService {
    baseUrl: string = '/api/v1/rclpma';
    urlEquiTecnica: string = `${this.baseUrl}/EquipeTecnica`;
    urlEquiCiBio: string = `${this.baseUrl}/EquipeCIBIO`;
    urlLocais: string = `${this.baseUrl}/Local`;
    urlProjeto: string = `${this.baseUrl}/Projeto`;
    urlConfigs: string = `${this.baseUrl}/Configs`;
    urlAMI: string = "api/v1/autorizacoes-cibio/MovimentacaoInterna";
    urlComunicados: string = `${this.baseUrl}/Comunicados`;

    public async getList(filtro:any = null){
      const { data, status } = await api.post<RCLpmaDto[]>(`${this.baseUrl}`, filtro);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public async getRCLPMAById(id: any){
      const { data, status } = await api.get<RCLpmaDto>(`${this.baseUrl}/${id}`);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    public async getRCLPMADatasComunicadosById(id: any) {
        const { data, status } = await api.get<RCLpma_Comunicados_Datas[]>(`${this.baseUrl}/datas/${id}`);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    public async getRCLPMAMateriaisAprovadosComunicadosById(id: any) {
        const { data, status } = await api.get<RCLpma_Comunicados_Materiais_Aprovados[]>(`${this.baseUrl}/materiaisaprovados/${id}`);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    public async getRCLPMAMateriaisPlantadosComunicadosById(id: any) {
        const { data, status } = await api.get<RCLpma_Comunicados_Materiais_Plantados[]>(`${this.baseUrl}/materiaisplantados/${id}`);
        if ([200, 201].indexOf(status) <= -1)
            throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public async getRCLPMAAction(acao: string, dto: any) {
      const { data, status } = await api.post(`${this.baseUrl}/action/${acao}`, dto);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public async DownloadFile(rcLpmaId:number, tipo: string, itemGuid: any, documentFileName:string){
      try {
        const { data, headers } = await apiFileDownload.get(`${this.baseUrl}/file/${rcLpmaId}/${tipo}/${itemGuid}`);
        let blob = new Blob([data], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        if (typeof a.download === "undefined") { 	window.location.href = url; }
        else {
          a.href = url; a.download = documentFileName ?? `download_${itemGuid}`;
          document.body.appendChild(a);
          a.click();
        }
      } catch (error:any) {
        console.log(error);
        throw new Error(error);       
      }
    }
      
    public async DownloadRCLPMAPDF(id: number, documentFileName:string, font: string, opcao:string){
       // this.DownloadRNPDF(`${this.urlRN06}/document`, id, documentFileName);
       try {
        //`${this.baseUrl}/rn35document
           const { data, headers } = await apiFileDownload. get(`${this.baseUrl}/document/${opcao}/${font}/${id}`);
        let blob = new Blob([data], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        if (typeof a.download === "undefined") { 	window.location.href = url; }
        else {
          a.href = url; a.download = documentFileName ?? `cqb_${id}`;
          document.body.appendChild(a);
          a.click();
        }
      } catch (error:any) {
        console.log(error);
        throw new Error(error);       
      }
    }

    public async DownloadRCLPMAPDFPublico(id: number, documentFileName: string, font: string) {
        // this.DownloadRNPDF(`${this.urlRN06}/document`, id, documentFileName);
        try {
            //`${this.baseUrl}/rn35document
            const { data, headers } = await apiFileDownload.get(`${this.baseUrl}/document/${font}/${id}`);
            let blob = new Blob([data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            if (typeof a.download === "undefined") { window.location.href = url; }
            else {
                a.href = url; a.download = documentFileName ?? `cqb_${id}`;
                document.body.appendChild(a);
                a.click();
            }
        } catch (error: any) {
            console.log(error);
            throw new Error(error);
        }
    }

    public async getRCLPMASaveFiles(id: number, dto:FormData){
      const { data, status } = await apiFile.post(`${this.baseUrl}/${id}`, dto);
        if ([200,201].indexOf(status) <= -1)
                throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }

    /******** Api's para Tratamento de Dados Pré-Configurados *********/

    public  async getConfigs (filter:any = null){
      const { data, status } = await api.post<any[]>(`${this.urlConfigs}`, filter);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }
    public  async getConfigsById (id: any){
      const { data, status } = await api.get<any>(`${this.urlConfigs}/${id}`);
        if ([200,201].indexOf(status) <= -1)
        throw new Error('Erro ao obter resposta do servidor!');
        return data;
    }
    public  async getConfigsSave (values:any){
      const { data, status } = await api.post<any[]>(`${this.urlConfigs}/save`, values);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }

    public async getDefaultData(){
      const { data, status } = await api.get<any>(`${this.urlConfigs}/GetConfigData/all`);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }
     /******* API's Para Tratamento de Locais para o LPMA *******/
     public async getAMI({codigo, solicitante, localEnvio, localDestino, cultura, dataInicial, dataFinal, origem, numeroAutorizacaoCibio}: any){
      //queries?.localEnvio ? queries?.localEnvio.value : ''
      //queries?.localDestino ? queries?.localDestino.value : ''
      //queries?.dataInicial ? _generateDate(new Date(queries?.dataInicial), 'En') : ''
      //queries?.dataFinal ? _generateDate(new Date(queries?.dataFinal), 'En') : ''
      //queries?.status ? queries.status.value : ''
      // &Codigo=${codigo ?? ''}
      const { data } = await api.get<AMISearchResult[]>(`${this.urlAMI}/obterporfiltro?Origem=${origem}&Requisitante=${solicitante}&LocalPartidaId=${localEnvio}&LocalDestinoId=${localDestino}&CulturaId=${cultura}&DataInicial=${dataInicial}&DataFinal=${dataFinal}&NumeroAutorizacaoCIBIO=${numeroAutorizacaoCibio}&Status=&Finalizadas=${true}`);
      return data;
    }
    public async getAMIById(id:any){
      const { data, status } = await api.get<AMIByIdToEdit>(`${this.urlAMI}/${id}`);
      if ([200,201].indexOf(status) <= -1)
      throw new Error('Erro ao obter resposta do servidor!');
      return data;
    }
     public async getAMICulturas(){
        const url1 = 'api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true'
        const { data } = await api.get<CultureResult[]>(url1);
        return data;
     }
     public async getAMIDataDefault(){
      const locations = await api.get<UnityStationResult[]>('api/v1/autorizacoes-cibio/UnidadeEstacao/obterporfiltro?Ativo=true');
      const events = await api.get<EventResult[]>('api/v1/autorizacoes-cibio/Evento/obterporfiltro?Ativo=true');
      const cultures = await api.get<CultureResult[]>('api/v1/autorizacoes-cibio/Cultura/obterporfiltro?Ativo=true');
      const material = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/material');
      const unidadeMedida = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/unidade-medida');
      const transporte = await api.get<Enum[]>('api/v1/autorizacoes-cibio/Enum/modo-transporte');
      return {
          locations : locations.data,
          events    : events.data,
          cultures  : cultures.data,
          material  : material.data,
          unidadeMedida : unidadeMedida.data,
          transporte : transporte.data,
      }
     }
}

export class RolesService {
  conext:   any;
  decode:   DecodedToken;
  roles:    any;
  constructor(_useContext:any) {
      this.conext = _useContext(Context);;//Context;//React.createContext(Context);// useContext(Context); };
      this.decode = jwtDecode(this.conext.token.access_token);
      this.roles  = this.decode.roles;
  }
  public getUserName(){
    //const { roles, unique_name }: DecodedToken = jwtDecode(token);
    return this.decode?.unique_name;
  }
  /*
  public isCreatRequisicao(){
    return isRCLPMACheckRoles(this.roles, RCLPMA_PERMISSIONS.CriarRequisicao)||
	  isRCLPMACheckRoles(this.roles, RCLPMA_PERMISSIONS.Admin) 
  }
  
  public isEditRequisicao() {
		return isRCLPMACheckRoles(this.roles, RCLPMA_PERMISSIONS.EditarRequisicao) ||
		isRCLPMACheckRoles(this.roles, RCLPMA_PERMISSIONS.Admin) 
	}

  public isCreatOREditRequisicao() {
    return this.isCreatRequisicao() || this.isEditRequisicao();
  }
  */

  public isTecnico() {
		return isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Tecnico)  ||
    isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.EditarMinhas)    ||
		isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Admin) 
	}

  public isTecnicoPermissao() {
    /**Assegura que apenas a permissão de tecnico seja testada*/
		return isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Tecnico)
    && !isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Regulatorio)
    && !isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Admin);
	}
	
  public isRegulatorio() {
		return isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Regulatorio) ||
    isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.CriarRequisicao)    ||
		isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Admin) 
	}

  public isAdmin(){
    return isRCLPMACheckRoles(this?.roles, RCLPMA_PERMISSIONS.Admin);
  }

}